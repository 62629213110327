<div class="noFile-card grid-container">
    <div class="grid-x align-middle grid-padding-y grid-padding-x">
        <div class="cell shrink">
            <i class="fas fa-image noFile-icon"></i>
        </div>
        <div class="cell auto">
            <div class="noFile-name">
                {{'empty.file.title' | translate}}
            </div>
            <div class="noFile-explain">
                {{'empty.file.explain' | translate}}
            </div>
        </div>
    </div>
</div>

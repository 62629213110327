<div class="grid-x grid-padding-y align-middle">
    <div class="cell shrink">
        <i class="fas fa-comment-alt userComment-icon"></i>
    </div>
    <div class="cell auto userComment-name">
        {{userName}}
    </div>
</div>

<div class="grid-x grid-margin-x">
    <div class="cell auto">
        <ng-content></ng-content>
    </div>
</div>

import {Component, OnInit} from '@angular/core';
import {Device} from 'lib-devices';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceSearchService} from '../../services/device-search/device-search.service';

@Component({
    selector: 'search-view',
    templateUrl: './search.view.html',
    styleUrls: ['./search.view.scss'],
    host: {class: 'cell auto cell-block-y'}
})
export class SearchView implements OnInit {

    devices: Array<Device>;


    constructor(private location: Location,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                public deviceSearchService: DeviceSearchService) {
    }


    setDevices($event: Array<Device>) {
        this.devices = $event;
    }

    ngOnInit(): void {
        this.deviceSearchService.initialiseDeviceSearch();
    }


}

<m-searchbar></m-searchbar>

<o-quick-filters></o-quick-filters>

<o-devices-container [devices]="deviceSearchService.devices"></o-devices-container>

<div *ngIf="deviceSearchService.devices && deviceSearchService.devices.length === 0"
     class="grid-y align-middle align-center noDevice-container">
    <div class="cell shrink grid-x align-center">
        <m-no-device class="cell shrink"></m-no-device>
    </div>
</div>

import {Component} from '@angular/core';

@Component({
    selector: 'a-alert-message',
    templateUrl: './a-alert-message.component.html',
    styleUrls: ['./a-alert-message.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-x align-middle'}
})
export class AAlertMessageComponent {

}

import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Device, DeviceAvailability, DeviceType} from 'lib-devices';

@Component({
    selector: 'o-device-res-header',
    templateUrl: './o-device-res-header.component.html',
    styleUrls: ['./o-device-res-header.component.scss']
})
export class ODeviceResHeaderComponent implements OnInit, OnChanges {

    // tslint:disable-next-line:variable-name
    private _device;

    loadingDevice = true;
    public availabilityCssClass = '';

    // Type host binding
    @HostBinding('class._phone') typeMobile: boolean = false;
    @HostBinding('class._tablet') typeTablet: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
        this.setTypeCssClass();
        this._device = this.getGhostDevice();
        this.setAvailabilityCssClass(this.device);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setTypeCssClass();
    }

    public setTypeCssClass() {
        if (this.device) {
            this.typeMobile = false;
            this.typeTablet = false;
            switch (this.device.type) {
                case 'MOBILE' :
                    this.typeMobile = true;
                    break;

                case 'TABLET' :
                    this.typeTablet = true;
                    break;
            }
        }
    }


    get device(): Device {
        return this._device;
    }

    @Input()
    set device(device: Device) {
        if (device) {
            this.loadingDevice = false;
            this.setAvailabilityCssClass(device);
            this._device = device;
        } else {
            this.loadingDevice = true;
            this._device = this.getGhostDevice();

        }

    }

    private setAvailabilityCssClass(device: Device) {
        if (device) {
            switch (device.availability) {
                case DeviceAvailability.AVAILABLE:
                    this.availabilityCssClass = '_available';
                    break;

                case DeviceAvailability.TAKEN:
                    this.availabilityCssClass = '_notAvailable';
                    break;

                case DeviceAvailability.BROKEN:
                    this.availabilityCssClass = '_broken';
                    break;

                default:
                    this.availabilityCssClass = '_undefined';
                    break;
            }
        }
    }

    private getGhostDevice(): Device {
        this.availabilityCssClass = '';
        return {
            associatedProjects: undefined,
            availability: undefined,
            batteryPercentage: 0,
            currentUser: null,
            id: '',
            lastNativeSyncDate: undefined,
            model: 'XXXXXXXXXXXXXX',
            name: '',
            os: '',
            osBlock: undefined,
            osVersion: '',
            spaceAvailable: 0,
            spaceTotal: 0,
            type: DeviceType.MOBILE,
            appVersion: '0.0.0',
            uuid: ''
        };
    }
}


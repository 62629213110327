import {Component, Input} from '@angular/core';
import {Device, DeviceAvailability} from 'lib-devices';

@Component({
    selector: 'm-device',
    templateUrl: './m-device.component.html',
    styleUrls: ['./m-device.component.scss']
})
export class MDeviceComponent {

    @Input() isGhost: boolean;
    @Input() device: Device;


    constructor() {
    }

    public getAvailabilityCssColor(availability: DeviceAvailability): string {
        switch (availability) {
            case DeviceAvailability.AVAILABLE:
                return '_available';

            case DeviceAvailability.TAKEN:
                return '_notAvailable';

            case DeviceAvailability.BROKEN:
                return '_broken';

            default:
                return '_undefined';
        }
    }
}


<div class="ghost">
    <a-section-title [isGhost]="true" [faIconClass]="'fas fa-bookmark'">
    </a-section-title>

    <spacer [size]="size"></spacer>

    <div class="ghost">
        <div class="xlarge-ghost-size ghost-height">
            A ghost content
        </div>
    </div>
</div>

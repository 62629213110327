import {Component, forwardRef, OnInit, ViewChild} from '@angular/core';
import {UserHttpService, UserReference} from 'lib-devices';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
    selector: 'a-user-select',
    templateUrl: './a-user-select.component.html',
    styleUrls: ['./a-user-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AUserSelectComponent),
        }
    ]

})
export class AUserSelectComponent implements OnInit, ControlValueAccessor {

    private changeSelectedUser: (UserReference) => void;
    public placeholder: string;
    public users: Array<UserReference> = [];
    public loading: boolean;
    private _selectedUserId: string;


    @ViewChild('selectField') selectField;


    constructor(private userHttpService: UserHttpService) {
    }


    get selectedUserId(): string {
        return this._selectedUserId;

    }

    set selectedUserId(userId: string) {

        if (this.changeSelectedUser !== undefined && this.users) {
            this.changeSelectedUser(this.users.find(userOption => userOption.userId === userId));
        }

        this._selectedUserId = userId;
    }


    ngOnInit(): void {

        this.userHttpService
            .getUsers().subscribe(users => this.users = users);
    }

    registerOnChange(fn: any): void {

        this.changeSelectedUser = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(user: UserReference): void {
        if (user !== null) {
            if (user) {
                this.selectedUserId = user.userId;
                this.changeSelectedUser(user);
            }
        }
    }

    userSearchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.userCode.toLocaleLowerCase().indexOf(term) > -1 ||
            item.userName.toLocaleLowerCase().indexOf(term) > -1;
    }


    blurField() {
        setTimeout(() => this.selectField.blur(), 200);
    }
}


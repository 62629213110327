<ng-container *ngIf="user && !isGhost">
    <div class="cell shrink">
        <div class="userDisplay-icon">
            <i class="fas fa-user"></i>
            <i class="userDisplay-supIcon " [ngClass]="displayIconFaClass"></i>
        </div>
    </div>

    <div class="cell userComment-name" [class.auto]="!displayVerticalMode" [class.shrink]="displayVerticalMode">
        <ng-container *ngIf="!abbreviated">
            {{'user.' + displayUserLabel | translate}}&nbsp;:
            <span class="font-bold">{{user.userName}}</span>
        </ng-container>

        <ng-container *ngIf="abbreviated">
            <span class="font-bold">{{user.userCode}}</span>
        </ng-container>
    </div>
</ng-container>

<div [class.ghost]="isGhost" *ngIf="isGhost">
    <ng-container *ngIf="!abbreviated">
        <div class="ghost-remote-img">
            A use display full name width
        </div>
    </ng-container>

    <ng-container *ngIf="abbreviated">
        <div class="ghost-remote-img">
            AAA
        </div>
    </ng-container>
</div>

import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'a-battery-display',
    templateUrl: './a-battery-display.component.html',
    styleUrls: ['./a-battery-display.component.scss']
    // tslint:disable-next-line:no-host-metadata-property
})
export class ABatteryDisplayComponent {


    @Input()
    public displayVerticalMode: boolean = false;

    @Input() class: string;

    @HostBinding('class')
    get hostClasses(): string {
        const commonCssClass: string = 'align-middle';
        let additionalCssClass: string;

        if (this.displayVerticalMode) {
            additionalCssClass = 'grid-y _vertical';
        } else {
            additionalCssClass = 'grid-x grid-padding-y _horizontal';
        }

        return [
            this.class,
            commonCssClass,
            additionalCssClass,
        ].join(' ');
    }


    private _batteryLevel: number;

    public getBatteryLevelFaIcon(): string {
        if (this._batteryLevel > 75) {
            return 'fas fa-battery-full';
        } else if (this._batteryLevel <= 75 && this._batteryLevel > 50) {
            return 'fas fa-battery-three-quarters';
        } else if (this._batteryLevel <= 50 && this._batteryLevel > 25) {
            return 'fas fa-battery-half';
        } else if (this._batteryLevel <= 25 && this._batteryLevel > 0) {
            return 'fas fa-battery-quarter';
        } else {
            return 'fas fa-battery-empty';
        }
    }

    get batteryLevel(): number {
        return this._batteryLevel;
    }

    @Input()
    set batteryLevel(value: number) {
        this._batteryLevel = value > 0 ? value : 0;
    }
}

import {Component, Input} from '@angular/core';

@Component({
    selector: 'a-app-version',
    templateUrl: './a-app-version.component.html',
    styleUrls: ['./a-app-version.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
})
export class AAppVersionComponent {

    @Input()
    public appVersion: string;
}

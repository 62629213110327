import {Component} from '@angular/core';

@Component({
    selector: 'a-no-files',
    templateUrl: './a-no-files.component.html',
    styleUrls: ['./a-no-files.component.scss']
})
export class ANoFilesComponent {

}


import {NgModule} from '@angular/core';
import {DeviceTranslateService} from './i18n/device-translate.service';
import {DeviceLoggerService} from './common/device-logger.service';
import {SharedDevicesErrorReportingService} from './error-handling/shared-devices-error-reporting.service';


@NgModule({
    providers: [
        DeviceTranslateService,
        DeviceLoggerService,
        SharedDevicesErrorReportingService,
    ],
    declarations: [],
    imports: []
})

export class ServicesModule {
}

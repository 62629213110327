import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'l-device-tabs-planning',
    templateUrl: './l-device-tabs-planning.component.html',
    styleUrls: ['./l-device-tabs-planning.component.scss']
})

export class LDeviceTabsPlanningComponent {

    @Input()
    public device;

    @Input()
    public storedUser;

    @Output()
    renewDevice = new EventEmitter<void>();

    public emitRenewDevice() {
        this.renewDevice.emit();
    }
}

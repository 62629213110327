<label [class._disabled]="disabled">

    <input type="radio"
           [value]="value"
           [ngModel]="checked"
           (ngModelChange)="onCheckedChange($event)"
           [disabled]="disabled"
           [name]="name">

    <span class="radio" *ngIf="label">
        {{label | translate}}
    </span>
</label>

import {Component, HostBinding, Input, OnChanges, OnInit} from '@angular/core';
import {DeviceAvailability} from 'lib-devices';

@Component({
    selector: 'a-device-name',
    templateUrl: './a-device-name.component.html',
    styleUrls: ['./a-device-name.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
})
export class ADeviceNameComponent implements OnInit, OnChanges {

    @Input()
    public deviceName: string;

    @Input()
    public isGhost: boolean = true;

    @Input()
    private availability: DeviceAvailability;

    // Availability host binding
    @HostBinding('class._available') isAvailable: boolean = false;
    @HostBinding('class._notAvailable') isNotAvailable: boolean = false;
    @HostBinding('class._broken') isBroken: boolean = false;
    @HostBinding('class._undefined') isUndefined: boolean = false;

    ngOnInit() {
        this.getAvailabilityColor();
    }

    ngOnChanges(changes: any) {
        this.getAvailabilityColor();
    }

    private getAvailabilityColor() {
        this.isAvailable = false;
        this.isNotAvailable = false;
        this.isBroken = false;
        this.isUndefined = false;

        switch (this.availability) {
            case DeviceAvailability.AVAILABLE:
                this.isAvailable = true;
                break;

            case DeviceAvailability.TAKEN:
                this.isNotAvailable = true;
                break;

            case DeviceAvailability.BROKEN:
                this.isBroken = true;
                break;
            default:
                this.isUndefined = true;
                break;
        }
    }
}

import {Injectable} from '@angular/core';

enum Level {
    DEBUG = 'debug',
    INFO = 'log',
    WARNING = 'warn',
    ERROR = 'error'
}

@Injectable()
export class DeviceLoggerService {

    constructor() {
    }

    public info(message: string, ...optionalParams: any[]) {
        this.log(Level.INFO, message, optionalParams);
    }

    public debug(message: string, ...optionalParams: any[]) {
        this.log(Level.DEBUG, message, optionalParams);
    }

    public warning(message: string, ...optionalParams: any[]) {
        this.log(Level.WARNING, message, optionalParams);
    }

    public error(message: string, ...optionalParams: any[]) {
        this.log(Level.ERROR, message, optionalParams);
    }

    private log(level: Level, message: string, optionalParams: any) {
        const loggerFunction = console[level] || console[Level.INFO];

        if (loggerFunction) {
            loggerFunction(message, ...optionalParams);
        }
    }
}

import {Component, OnInit} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {ApiInfoHttpService} from 'lib-devices';

@Component({
    selector: 'a-api-info-display',
    styleUrls: ['a-api-info-display.component.scss'],
    templateUrl: './a-api-info-display.component.html'
})
export class AApiInfoDisplayComponent implements OnInit {

    serverVersion: string = '0.0.0';
    loadingServerVersion = true;

    constructor(private apiInfoHttpService: ApiInfoHttpService) {
        apiInfoHttpService.getServerInfo()
            .pipe(
                finalize(() => this.loadingServerVersion = false)
            )
            .subscribe(serverInfo =>
                this.serverVersion = serverInfo.version
            );
    }

    ngOnInit(): void {
    }

}

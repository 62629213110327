import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_APP_LANGUAGE} from '../services/i18n/i18n.config';
import {moment_fr} from '../../assets/i18n/moment_fr';
import * as moment from 'moment';
import {SharedDevicesErrorReportingService} from '../services/error-handling/shared-devices-error-reporting.service';
import {ApiInfoHttpService} from '../../../../lib-devices/src/lib/services/http/api-info-http.service';
import {ApiInfo} from 'lib-devices';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent {

    constructor(translateService: TranslateService,
                public sharedDevicesErrorReportingService: SharedDevicesErrorReportingService,
                apiInfoHttpService: ApiInfoHttpService) {

        translateService.use(translateService.getBrowserCultureLang());
        translateService.setDefaultLang(DEFAULT_APP_LANGUAGE);
        moment.locale('fr', moment_fr);

        apiInfoHttpService.getServerInfo().subscribe(
            (apiInfo: ApiInfo) => sharedDevicesErrorReportingService.startReporting({releaseName: apiInfo.version})
        );


    }


}

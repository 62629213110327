<dp-date-picker
    (onSelect)="onChange($event)"
    (open)="onOpen()"
    [config]="config"
    mode="day"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="'label.chooseDate'|translate"
    [displayDate]="realDate"
    [ngModel]="realDate">
</dp-date-picker>

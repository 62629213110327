import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DeviceFile} from 'lib-devices';

@Component({
    selector: 'm-path-display',
    templateUrl: './m-path-display.component.html',
    styleUrls: ['./m-path-display.component.scss']
})
export class MPathDisplayComponent {


    private _path: string;

    folders: Array<DeviceFile> = [];

    @Output() changePathEmitter: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    get path(): string {
        return this._path;
    }

    @Input()
    set path(path: string) {
        this.folders = this.makeFoldersFromPath(path);
        this._path = path;
    }


    private makeFoldersFromPath(path: string) {
        const folders: Array<DeviceFile> = [];
        const folderNames = path.split('/');
        let i = 0;
        for (const folderName of folderNames) {
            i++;
            folders.push({name: folderName, contentType: 'folder', path: folderNames.slice(0, i).join('/')});

        }
        return folders;
    }

    changePath(path: string) {
        this.changePathEmitter.emit(path);
    }
}

import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {DeviceFile} from 'lib-devices';

@Component({
    selector: 'a-small-folder-display',
    templateUrl: './a-small-folder-display.component.html',
    styleUrls: ['./a-small-folder-display.component.scss']
})
export class ASmallFolderDisplayComponent {

    @Input()
    folder: DeviceFile;

    @Input()
    isFirst: boolean = false;

    @Input()
    isLast: boolean = false;

    @Input() class: string;
    @HostBinding('class')
    get hostClasses(): string {
        const commonCssClass: string = '';
        let additionalCssClass: string;

        if (this.isFirst) {
            additionalCssClass = '_first';
        }

        if (this.isLast) {
            additionalCssClass += ' _last';
        }

        return [
            this.class,
            commonCssClass,
            additionalCssClass,
        ].join(' ');
    }

}

<div class="cell shrink syncDisplay-icon">
    <i class="fas fa-sync"></i>
</div>
<div class="cell auto">
    <ng-container *ngIf="date">
        {{'label.lastSyncDate' | translate}} : <span class="syncDisplay-date">{{date | amTimeAgo}}</span>
    </ng-container>
    <ng-container *ngIf="!date">
        {{'label.neverSynced' | translate}}
    </ng-container>
</div>

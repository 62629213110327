import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
// @ts-ignore
import {
    CURRENT_USER,
    Device,
    DeviceAvailability,
    DeviceHttpService,
    DeviceStorageService,
    Reservation,
    ReservationHttpService,
    ReservationType,
    ReservationUtil,
    UserReference
} from 'lib-devices';
import {DeviceLoggerService} from '../../../services/common/device-logger.service';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {NgForm} from '@angular/forms';
import {noop, Observable, of} from 'rxjs';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'o-device-res-form',
    templateUrl: './o-device-res-form.component.html',
    styleUrls: ['./o-device-res-form.component.scss']
})
export class ODeviceResFormComponent implements OnInit {


    deviceAvailability = DeviceAvailability;
    currentReservation: Reservation;
    loadingReservation = true;

    private _storedUser: UserReference;

    loadingDevice = true;

    private _device: Device;
    reservationTypes = ReservationType;

    @Output()
    renewDevice = new EventEmitter<void>();


    private _reservationEndDate;
    selectedUser: UserReference;

    @ViewChild(NgForm)
    form: NgForm;


    reservationEndTime: ReservationType;

    tomorrow = moment().add(1, 'day');

    pushingReservation = false;
    cancelingReservation = false;
    extendingReservation = false;


    constructor(private reservationHttpService: ReservationHttpService,
                private deviceHttpService: DeviceHttpService,
                private deviceLoggerService: DeviceLoggerService,
                private deviceStorageService: DeviceStorageService,
                private translateService: TranslateService) {

        this.deviceLoggerService = deviceLoggerService;


    }

    ngOnInit(): void {

    }


    get storedUser(): UserReference {
        return this._storedUser;
    }

    @Input()
    set storedUser(user: UserReference) {
        this._storedUser = user;
        this.selectedUser = user;
    }


    @Input()
    set device(device: Device) {
        if (device) {
            this.loadingDevice = false;
            this.extendingReservation = false;
            this.cancelingReservation = false;
            this.pushingReservation = false;
        }
        this.resetDevice(device);
    }

    get device() {
        return this._device;
    }

    private resetDevice(device: Device) {
        if (device) {
            this._device = device;
            this.loadingDevice = false;
            if (device.availability === DeviceAvailability.TAKEN) {
                this.getCurrentReservation();
            }
        } else {
            this.loadingDevice = true;
        }
    }


    getCurrentReservation() {
        this.reservationHttpService.getCurrentReservation(this.device.id)
            .pipe(
                finalize(() => this.loadingReservation = false)
            )
            .subscribe(
                data => {
                    this.currentReservation = data;
                },
                error => this.deviceLoggerService.warning(error.toString())
            );
    }


    isAfternoon() {
        const date = new Date();
        return date.getHours() >= 12;
    }

    onSubmitReservation() {


        const [startTime, endTime] = this.reservationEndTime !== ReservationType.BY_END_DATE ? ReservationUtil
                .getReservationBounds(this.reservationEndTime) :
            ReservationUtil.getReservationBounds(ReservationType.BY_END_DATE, this.reservationEndDate);

        const user = this.selectedUser;

        const reservation: Reservation = {
            device: this.device.id,
            userReference: user,
            start: startTime,
            end: endTime
        };

        this.deviceStorageService.save(CURRENT_USER, user);
        this.pushingReservation = true;
        this.reservationHttpService
            .reserveDevice(reservation)
            .pipe(
                tap(() => this.renewDevice.emit()),
            ).subscribe(
            () => noop(),
            error => {
                this.deviceLoggerService.warning(error.toString());
                this.renewDevice.emit();
            }
        );

    }


    cancelReservation(): Observable<void> {
        this.cancelingReservation = true;
        return this.reservationHttpService
            .cancelReservation(this.currentReservation.id)
            .pipe(
                tap(() => this.renewDevice.emit()),
                map(() => noop()),
                catchError(error => {
                    this.renewDevice.emit();
                    return of(error);
                })
            );
    }

    showExtendedNotification() {
        Swal.fire({
            position: 'bottom-end',
            icon: 'success',
            title: this.translateService.instant('reservation.extended'),
            showConfirmButton: false,
            timer: 1500
        }).then();
    }

    extendReservation(hours: number) {
        this.extendingReservation = true;
        this.currentReservation.end = moment(this.currentReservation.end).add(hours, 'hour');
        this.showExtendedNotification();
        this.reservationHttpService
            .putReservation(this.currentReservation)
            .pipe(
                tap(() => this.renewDevice.emit())
            )
            .subscribe(
                () => noop(),
                error => this.deviceLoggerService.warning(error.toString())
            );
    }

    get reservationEndDate() {
        return this._reservationEndDate;
    }

    set reservationEndDate(value: any) {
        this._reservationEndDate = value;
    }

    selectRadioButton() {
        this._reservationEndDate = undefined;

    }

    reservationFormIsValid() {
        return (!this.reservationEndDate && !this.reservationEndTime) ||
            (this.reservationEndTime === this.reservationTypes.BY_END_DATE && !this.reservationEndDate) ||
            !this.selectedUser;
    }

    askIfSureCancelReservation() {
        const askIfSureCancelDialog = Swal.mixin({
            customClass: {
                confirmButton: 'button _primary',
                cancelButton: 'button _secondary'
            },
            buttonsStyling: true
        });

        askIfSureCancelDialog.fire({
            title: this.translateService.instant('askIfSureDialogs.areYouSure'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translateService.instant('common.yes'),
            cancelButtonText: this.translateService.instant('common.cancel'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.cancelReservation().subscribe(
                    () =>
                        Swal.fire({
                            position: 'bottom-end',
                            icon: 'success',
                            title: this.translateService.instant('askIfSureDialogs.reservationCanceled'),
                            showConfirmButton: false,
                            timer: 1500
                        }).then()
                );
            }
        });
    }
}

import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DeviceAvailability, UserReference} from 'lib-devices';

@Component({
    selector: 'a-user-display',
    templateUrl: './a-user-display.component.html',
    styleUrls: ['./a-user-display.component.scss']
    // tslint:disable-next-line:no-host-metadata-property
})
export class AUserDisplayComponent implements OnInit, OnChanges {

    @Input()
    public displayVerticalMode: boolean = false;

    @Input() class: string;
    @HostBinding('class')
    get hostClasses(): string {
        const commonCssClass: string = 'align-middle';
        let additionalCssClass: string;

        if (this.displayVerticalMode) {
            additionalCssClass = 'grid-y _vertical';
        } else {
            additionalCssClass = 'grid-x grid-padding-y _horizontal';
        }

        return [
            this.class,
            commonCssClass,
            additionalCssClass,
        ].join(' ');
    }

    @Input()
    public isGhost: boolean = false;

    @Input()
    public user: UserReference;

    @Input()
    public abbreviated: boolean = false;

    @Input()
    private availability: DeviceAvailability;

    public displayIconFaClass: string = '';
    public displayUserLabel: string = '';

    // Availability host binding
    @HostBinding('class._available') isAvailable: boolean = false;
    @HostBinding('class._notAvailable') isNotAvailable: boolean = false;
    @HostBinding('class._broken') isBroken: boolean = false;
    @HostBinding('class._undefined') isUndefined: boolean = false;

    ngOnInit() {
        this.getAvailabilityColor();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getAvailabilityColor();
    }

    private getAvailabilityColor() {
        this.isAvailable = false;
        this.isNotAvailable = false;
        this.isBroken = false;
        this.isUndefined = false;

        switch (this.availability) {
            case DeviceAvailability.AVAILABLE:
                this.isAvailable = true;
                this.displayIconFaClass = 'fas fa-history';
                this.displayUserLabel = 'last';
                break;

            case DeviceAvailability.TAKEN:
                this.isNotAvailable = true;
                this.displayIconFaClass = 'fas fa-map-marker-alt';
                this.displayUserLabel = 'current';
                break;

            case DeviceAvailability.BROKEN:
                this.isBroken = true;
                this.displayIconFaClass = 'fas fa-history';
                this.displayUserLabel = 'last';
                break;

            default:
                this.isUndefined = true;
                break;
        }
    }
}

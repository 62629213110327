<a-quick-filter-button (click)="toggleQuickFilter(deviceAvailabilities.AVAILABLE)"
                       [active]="this.availabilitiesSelected.indexOf(deviceAvailabilities.AVAILABLE)>-1"
                       [color]="getAvailabilityColor('AVAILABLE')">
    {{'availability.AVAILABLE' | translate}}
</a-quick-filter-button>

<a-quick-filter-button (click)="toggleQuickFilter(deviceAvailabilities.TAKEN)"
                       [active]="this.availabilitiesSelected.indexOf(deviceAvailabilities.TAKEN)>-1"
                       [color]="getAvailabilityColor('TAKEN')">
    {{'availability.TAKEN' | translate}}
</a-quick-filter-button>

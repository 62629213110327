import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiInfo} from '../../domain/api-info.model';

@Injectable({
    providedIn: 'root'
})
export class ApiInfoHttpService {

    constructor(private http: HttpClient) {
    }


    getServerInfo(): Observable<ApiInfo> {
        return this.http.get<ApiInfo>('/info');
    }
}

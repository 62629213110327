import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DeviceLoggerService} from '../common/device-logger.service';

@Injectable()
export class DeviceTranslateService {

    constructor(private readonly translateService: TranslateService,
                private readonly loggerService: DeviceLoggerService) {
    }

    public translate(labelKey: string, interpolateParams?: Object): string {
        const label = !!labelKey ? this.translateService.instant(labelKey, interpolateParams) : labelKey;

        if (label === labelKey) {
            this.loggerService.warning(`No label value found for key '${labelKey}' and language '${this.translateService.currentLang}'`);
        }

        return label;
    }

    public translateOrNull(labelKey: string, interpolateParams?: Object): string {
        const label = !!labelKey ? this.translateService.instant(labelKey, interpolateParams) : labelKey;

        if (label === labelKey) {
            this.loggerService.warning(`No label value found for key '${labelKey}' and language '${this.translateService.currentLang}'`);
            return null;
        }

        return label;
    }

    public translateWithDefault(labelKey: string, defaultLabelKey: string, interpolateParams?: Object): string {
        let label = this.translateService.instant(labelKey, interpolateParams);
        if (label === labelKey) {
            label = this.translateService.instant(defaultLabelKey, interpolateParams);
        }
        return label;
    }
}

import {Component, Input} from '@angular/core';

@Component({
    selector: 'a-quick-filter-button',
    templateUrl: './a-quick-filter-button.component.html',
    styleUrls: ['./a-quick-filter-button.component.scss']
    // tslint:disable-next-line:no-host-metadata-property
})
export class AQuickFilterButtonComponent {

    @Input()
    public color: string = '#EA2631';

    @Input()
    public active: boolean = false;
}

<div class="reveal large" data-reveal #imgPopup>
    <div class="grid-container">
        <div class="grid-x grid-margin-x">
            <div class="cell shrink filePreview">
                <img *ngIf="deviceFile?.contentType !== 'folder'" [src]="fileUrl">
                <button class="filePreview-action _prev button _iconOnly" *ngIf="canGoBack" [disabled]="!canGoBack"
                        (click)="showPreviousFile()"><i class="fas fa-chevron-left"></i></button>
                <button class="filePreview-action _next button _iconOnly" *ngIf="canGoForward"
                        [disabled]="!canGoForward" (click)="showNextFile()"><i class="fas fa-chevron-right"></i>
                </button>
            </div>
            <div class="cell auto">
                <a-section-title [faIconClass]="'fas fa-image'">
                    {{'files.detail' | translate}}
                </a-section-title>

                <div class="grid-x align-middle grid-margin-x">
                    <div class="cell auto">
                        <div class="fileDisplay-name" *ngIf="!renamingFile">{{deviceFile?.name}}</div>
                        <label *ngIf="renamingFile">
                            <input type="text" class="no-margin-bottom" (keyup.enter)="renameFile()"
                                   [(ngModel)]="newFileName" (click)="$event.stopPropagation()">
                        </label>
                    </div>
                    <div class="cell shrink">
                        <button class="button tiny _iconOnly no-margin-bottom" *ngIf="!renamingFile"
                                (click)="renamingFile = !renamingFile; $event.stopPropagation()">
                            <i class="fas fa-pen"></i>
                        </button>
                        <button class="button tiny _iconOnly saveNameButton no-margin-bottom" *ngIf="renamingFile"
                                (click)="renameFile(); renamingFile = !renamingFile; $event.stopPropagation()">
                            <i class="fas fa-check"></i>
                        </button>
                    </div>
                </div>

                {{deviceFile?.path}}.{{deviceFile?.contentType}}

                <div class="fileDisplay-creationDate">
                    {{'files.add' | translate}} {{deviceFile?.creationDate | amTimeAgo}}
                </div>

                <spacer [size]="64"></spacer>

                <div class="grid-x grid-margin-x align-center">
                    <div class="cell shrink">
                        <a-button-default [labelKey]="'common.download'"
                                          (click)="downloadFile(deviceFile)"></a-button-default>
                    </div>
                    <div class="cell shrink">
                        <a-button-delete [labelKey]="'common.delete'"
                                         (click)="askIfSureDelete(deviceFile)"></a-button-delete>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <i class="fas fa-window-close closeButton" (click)="closePopup()"></i>
</div>

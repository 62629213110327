<div class="device-card grid-y" [routerLink]="['/device', device.id]"
     [ngClass]="getAvailabilityCssColor(device.availability)">
    <div [class.ghost]="isGhost" class="cell auto grid-x grid-padding-y grid-container">

        <a-device-preview class="cell shrink" [device]="device"></a-device-preview>

        <div class="cell auto">
            <a-os-label [device]="device" [isGhost]="isGhost"></a-os-label>

            <div class="grid-x grid-margin-x">
                <div class="cell auto">
                    <div class="device-name xlarge-ghost-size">{{device.model}}</div>
                </div>
                <div class="cell shrink">
                    <a-battery-display *ngIf="device.batteryPercentage" [displayVerticalMode]="true"
                                       [batteryLevel]="device.batteryPercentage"></a-battery-display>

                    <ng-container *ngIf="device.currentUser">
                        <a-user-display [user]="device.currentUser" [isGhost]="isGhost"
                                        [abbreviated]="true" [displayVerticalMode]="true"
                                        [availability]="device.availability"></a-user-display>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <a-device-name [isGhost]="isGhost" class="cell shrink" [availability]="device.availability"
                   [deviceName]="device.name"></a-device-name>
</div>

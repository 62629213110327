<div class="noFolder-card grid-container">
    <div class="grid-x align-middle grid-padding-y grid-padding-x">
        <div class="cell shrink">
            <i class="fas fa-folder-plus noFolder-icon"></i>
        </div>
        <div class="cell auto">
            <div class="noFolder-name">
                {{'empty.folder.title' | translate}}
            </div>
            <div class="noFolder-explain">
                {{'empty.folder.explain' | translate}}
            </div>
        </div>
    </div>
</div>

<div class="cell auto grid-y">
    <ul class="tabs cell shrink grid-x">
        <li *ngFor="let item of items"
            (click)="navigateTo(item.fragment)"
            [class._selected]="item.selected"
            class="cell shrink tabs-item">
            <i [ngClass]="item.icon" class="tabs-icon"></i>
            {{item.title}}
        </li>
    </ul>

    <div class="cell auto grid-y tabs-content">
        <div class="cell auto cell-block-y tabs-panel is-active">
            <ng-content></ng-content>
        </div>
    </div>
</div>

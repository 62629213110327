<ng-select [items]="oss$ | async"
           bindLabel="label"
           bindValue="value"
           placeholder="{{'common.all' | translate}}"
           [(ngModel)]="selectedOs"
           [searchable]="false">
    <ng-template ng-label-tmp let-item="item">
        <span>{{ item.label && 'OsType.' + item.label | translate}} </span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
        <span>{{ item.label && 'OsType.' + item.label | translate}} </span>
    </ng-template>

</ng-select>

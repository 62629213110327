import {Component, forwardRef, OnInit} from '@angular/core';
import {DeviceHttpService, DeviceType} from 'lib-devices';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
    selector: 'a-device-type-select',
    templateUrl: './a-device-type-select.component.html',
    styleUrls: ['./a-device-type-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ADeviceTypeSelectComponent),
        }
    ]

})
export class ADeviceTypeSelectComponent implements OnInit, ControlValueAccessor {
    private changeSelectedType: (DeviceType) => void;
    public placeholder: string;

    get selectedType(): DeviceType {

        return this._selectedType;
    }

    set selectedType(value: DeviceType) {

        if (this.changeSelectedType !== undefined) {
            this.changeSelectedType(value);
        }

        this._selectedType = value;
    }


    public types$: Observable<Array<IOption>>;

    public loading: boolean;

    private _selectedType: DeviceType = undefined;


    constructor(private deviceHttpService: DeviceHttpService) {


    }

    ngOnInit(): void {
        this.types$ = this.deviceHttpService.getTypes().pipe(map(typeArray => typeArray.map(
            type => {
                return {
                    label: type,
                    value: type
                };
            })));

    }

    registerOnChange(fn: any): void {

        this.changeSelectedType = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(type: DeviceType): void {
        if (type !== null) {
            if (type) {
                this.selectedType = type;
            }
        }
    }


}


interface IOption {
    label: string;
    value: any;
    disabled?: boolean;
}

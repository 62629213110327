export const enum Language {
    FR = 'fr'
}

export const DEFAULT_APP_LANGUAGE: string = Language.FR;

// cannot rely on translateService.getLangs() in order to find out available
// languages because languages are async loaded with TranslateHttpLoader
// LOCALE_ID and language interceptor need to know available languages right after app initialization
export const AVAILABLE_APP_LANGUAGES: string[] = [Language.FR];

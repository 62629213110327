<div class="cell shrink grid-x">
    <div class="cell shrink font-bold">
        Disponibilité :
    </div>
    <div class="cell shrink">
        <m-quick-availability-filter></m-quick-availability-filter>
    </div>
</div>

<div class="cell shrink grid-x">
    <div class="cell shrink font-bold">
        Type :
    </div>
    <div class="cell shrink">
        <m-quick-type-filter></m-quick-type-filter>
    </div>
</div>

<div class="cell shrink grid-x">
    <div class="cell shrink font-bold">
        Système :
    </div>
    <div class="cell shrink">
        <m-quick-os-filter></m-quick-os-filter>
    </div>
</div>

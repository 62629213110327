import {TranslateService} from '@ngx-translate/core';
import {AVAILABLE_APP_LANGUAGES, DEFAULT_APP_LANGUAGE} from './i18n.config';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function getLocaleId(translateService: TranslateService): string {
    let localeId: string;

    if (AVAILABLE_APP_LANGUAGES.includes(translateService.getBrowserLang())) {
        localeId = translateService.getBrowserCultureLang() || translateService.getBrowserLang();
    } else {
        localeId = DEFAULT_APP_LANGUAGE;
    }

    return localeId;
}

export function createTranslateLoader(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

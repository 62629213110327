import {Component} from '@angular/core';
import {DeviceSearchService} from '../../../services/device-search/device-search.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'm-quick-os-filter',
    templateUrl: './m-quick-os-filter.component.html',
    styleUrls: ['./m-quick-os-filter.component.scss']
})
export class MQuickOsFilterComponent {

    ossSelected: Array<string> = [];


    constructor(private deviceSearchService: DeviceSearchService) {
        deviceSearchService.deviceSearchSubject
            .pipe(
                map(deviceSearch => deviceSearch.oss),
                map(oss => oss === undefined ? [] : oss)
            )
            .subscribe(types => this.ossSelected = types);

    }


    toggleQuickFilter(os: string) {
        const index = this.ossSelected.indexOf(os);
        if (index > -1) {
            this.ossSelected.splice(index, 1);
        } else {
            this.ossSelected.push(os);
        }
        this.updateDeviceSearch();
    }

    private updateDeviceSearch() {
        const deviceSearch = this.deviceSearchService.deviceSearchSubject.getValue();
        deviceSearch.oss = this.ossSelected;
        this.deviceSearchService.deviceSearchSubject.next(deviceSearch);
    }


    public getOsColor(os: string) {
        switch (os) {
            case 'IOS' :
                return '#5AC8FA';

            case 'ANDROID' :
                return '#A4C639';

            case 'WINDOWS' :
                return '#0078D7';

        }
    }
}


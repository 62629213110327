import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Project} from 'lib-devices';

@Component({
    selector: 'a-associated-project',
    templateUrl: './a-associated-project.component.html',
    styleUrls: ['./a-associated-project.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-x grid-padding-x align-middle'}
})
export class AAssociatedProjectComponent {

    @Input()
    public project: Project;

    @Output()
    public removeClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    public removeProject(event: MouseEvent): void {
        event.stopPropagation();
        this.removeClick.emit(event);
    }

}

import {Component, Input} from '@angular/core';

@Component({
    selector: 'a-section-title',
    templateUrl: './a-section-title.component.html',
    styleUrls: ['./a-section-title.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-x align-middle'}
})
export class ASectionTitleComponent {

    @Input()
    public isGhost: boolean = false;

    @Input()
    public faIconClass: string;

    @Input()
    public labelKey: string;
}

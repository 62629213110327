<a-quick-filter-button (click)="toggleQuickFilter('IOS')"
                       [active]="this.ossSelected.indexOf('IOS')>-1"
                       [color]="getOsColor('IOS')">
    {{'OsType.IOS' | translate}}
</a-quick-filter-button>

<a-quick-filter-button (click)="toggleQuickFilter('ANDROID')"
                       [active]="this.ossSelected.indexOf('ANDROID')>-1"
                       [color]="getOsColor('ANDROID')">
    {{'OsType.ANDROID' | translate}}
</a-quick-filter-button>

<a-quick-filter-button (click)="toggleQuickFilter('WINDOWS')"
                       [active]="this.ossSelected.indexOf('WINDOWS')>-1"
                       [color]="getOsColor('WINDOWS')">
    {{'OsType.WINDOWS' | translate}}
</a-quick-filter-button>

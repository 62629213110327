import {Component, Input, OnInit} from '@angular/core';
import {ATabComponent} from '../../Atoms/a-tab/a-tab.component';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';

@Component({
    selector: 'm-tabs',
    templateUrl: './m-tabs.component.html',
    styleUrls: ['./m-tabs.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
})
export class MTabsComponent implements OnInit {

    @Input()
    public items: Array<ATabComponent> = [];
    public selectedItem: ATabComponent;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {


    }

    ngOnInit(): void {
        this.activatedRoute.fragment
            .pipe(
                map(fragment => fragment ? fragment : this.items[1].fragment)
            )
            .subscribe(
                fragment => {
                    this.selectItem(
                        this.items.find(item => item.fragment === fragment)
                    );
                }
            );
    }

    public registerTab(item: ATabComponent): void {
        if (this.items.length === 0) {
            item.selected = true;
            this.selectedItem = item;
        }

        this.items.push(item);
    }

    public selectItem(item: ATabComponent): void {
        this.selectedItem.selected = false;
        item.selected = true;
        this.selectedItem = item;
    }


    navigateTo(fragment: string) {
        this.router.navigate([], {fragment}).then();
    }
}

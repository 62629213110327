import {Component, Input} from '@angular/core';

@Component({
    selector: 'a-user-comment',
    templateUrl: './a-user-comment.component.html',
    styleUrls: ['./a-user-comment.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
})
export class AUserCommentComponent {

    @Input()
    public userName: string;
}

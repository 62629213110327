import {NgModule} from '@angular/core';

import {RootComponent} from './root.component';
import {RouterModule} from '@angular/router';
import {routes} from './routes';
import {DeviceComponentsModule} from '../components/components.module';
import {SearchView} from './search/search.view';
import {CommonModule} from '@angular/common';
import {DeviceModule} from './device/device.module';
import {AppHeaderComponent} from './app-header.component';


const components = [
    CommonModule,
    DeviceComponentsModule
];

@NgModule({
    declarations: [SearchView, RootComponent, AppHeaderComponent],
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}), DeviceComponentsModule, CommonModule],
    exports: [RouterModule, DeviceModule]


})
export class ViewsModule {
}

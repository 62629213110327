import {Component, Input} from '@angular/core';
import {Device} from 'lib-devices';

@Component({
    selector: 'a-os-label',
    templateUrl: './a-os-label.component.html',
    styleUrls: ['./a-os-label.component.scss']
    // tslint:disable-next-line:no-host-metadata-property
})
export class AOsLabelComponent {

    @Input()
    public device: Device;

    @Input()
    public isGhost: boolean = false;
}

import {Component, Input} from '@angular/core';

@Component({
    selector: 'a-reservation-time-left',
    templateUrl: './a-reservation-time-left.component.html',
    styleUrls: ['./a-reservation-time-left.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-x align-middle align-center'}
})
export class AReservationTimeLeftComponent {

    @Input()
    time: any;
}

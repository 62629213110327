import {Component} from '@angular/core';
import {DeviceAvailability} from 'lib-devices';
import {DeviceSearchService} from '../../../services/device-search/device-search.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'm-quick-availability-filter',
    templateUrl: './m-quick-availability-filter.component.html',
    styleUrls: ['./m-quick-availability-filter.component.scss']
})
export class MQuickAvailabilityFilterComponent {


    deviceAvailabilities = DeviceAvailability;

    availabilitiesSelected: Array<DeviceAvailability> = [];

    constructor(private deviceSearchService: DeviceSearchService) {
        deviceSearchService.deviceSearchSubject
            .pipe(
                map(deviceSearch => deviceSearch.availabilities),
                map(availabilities => availabilities === undefined ? [] : availabilities)
            )
            .subscribe(availabilities => this.availabilitiesSelected = availabilities);

    }


    toggleQuickFilter(availability: DeviceAvailability) {
        const index = this.availabilitiesSelected.indexOf(availability);
        if (index > -1) {
            this.availabilitiesSelected.splice(index, 1);
        } else {
            this.availabilitiesSelected.push(availability);
        }
        this.updateDeviceSearch();
    }

    private updateDeviceSearch() {
        const deviceSearch = this.deviceSearchService.deviceSearchSubject.getValue();
        deviceSearch.availabilities = this.availabilitiesSelected;
        this.deviceSearchService.deviceSearchSubject.next(deviceSearch);
    }


    public getAvailabilityColor(availability: string) {
        switch (availability) {
            case DeviceAvailability.AVAILABLE :
                return '#56C765';

            case DeviceAvailability.TAKEN :
                return '#FFAB38';

            case DeviceAvailability.BROKEN :
                return '#EA5326';

        }
    }
}


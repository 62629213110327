import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DeviceHttpService, DeviceSearch} from 'lib-devices';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {DeviceLoggerService} from '../../../services/common/device-logger.service';
import {DeviceSearchService} from '../../../services/device-search/device-search.service';

@Component({
    selector: 'm-searchbar',
    templateUrl: './m-searchbar.component.html',
    styleUrls: ['./m-searchbar.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-x grid-padding-x align-right align-stretch'}
})


export class MSearchbarComponent implements OnInit, OnDestroy {

    @ViewChild('searchField') searchField: ElementRef;

    public keyUp = new Subject<KeyboardEvent>();

    private subscription: Subscription;
    public deviceSearch: DeviceSearch = {};

    modelOrName: string;

    constructor(private deviceHttpService: DeviceHttpService,
                private deviceLoggerService: DeviceLoggerService,
                private deviceSearchService: DeviceSearchService
    ) {

    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


    ngOnInit(): void {

        this.subscription = this.keyUp
            .pipe(
                debounceTime(100),
                distinctUntilChanged()
            ).subscribe(
                () => {
                    const deviceSearch = this.deviceSearchService.deviceSearchSubject.getValue();
                    deviceSearch.modelOrName = this.modelOrName;
                    this.deviceSearchService.deviceSearchSubject.next(deviceSearch);
                },
                error => this.deviceLoggerService.warning(error.toString()
                ));

        this.deviceSearchService.deviceSearchSubject
            .pipe(
                map(deviceSearch => deviceSearch.modelOrName),
                filter(modelOrName => modelOrName !== this.modelOrName)
            )
            .subscribe(modelOrName => this.modelOrName = modelOrName);
    }


}


import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DeviceFile} from 'lib-devices';
import * as Foundation from 'foundation-sites/js/foundation';
import * as $ from 'jquery';


@Component({
    selector: 'm-file-preview',
    templateUrl: './m-file-preview.component.html',
    styleUrls: ['./m-file-preview.component.scss']
})
export class MFilePreviewComponent implements OnInit, AfterViewInit {

    @Input()
    deviceFile: DeviceFile;

    @Input()
    deviceId: string;

    @Input()
    fileUrl: string;

    @Input()
    canGoBack: boolean = false;

    @Input()
    canGoForward: boolean = false;

    @Input()
    set showPreview(value: boolean) {
        if (value) {
            this.imgPopup.open();
        } else {
            this.closePopup();
        }
    }

    @Output()
    public closeFilePreviewEmitter: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public showPreviousFileEmitter: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public showNextFileEmitter: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public downloadFileEmitter: EventEmitter<DeviceFile> = new EventEmitter<DeviceFile>();

    @Output()
    public askIfSureDeleteEmitter: EventEmitter<DeviceFile> = new EventEmitter<DeviceFile>();

    @ViewChild('imgPopup')
    public imgPopupEl: ElementRef;
    private imgPopup: Foundation.Reveal;


    renamingFile: boolean = false;

    newFileName: string;


    constructor() {
    }

    ngAfterViewInit(): void {
        this.imgPopup = new Foundation.Reveal($(this.imgPopupEl.nativeElement));
    }

    ngOnInit(): void {
    }

    renameFile() {

    }


    downloadFile(deviceFile: DeviceFile) {
        this.downloadFileEmitter.emit(deviceFile);
    }

    public closePopup(): void {
        this.imgPopup.close();
        this.closeFilePreviewEmitter.emit();
    }

    showPreviousFile() {
        this.showPreviousFileEmitter.emit();
    }

    showNextFile() {
        this.showNextFileEmitter.emit();
    }

    askIfSureDelete(deviceFile: DeviceFile) {
        this.askIfSureDeleteEmitter.emit(deviceFile);

    }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'a-ghost-form',
    templateUrl: './a-ghost-form.component.html',
    styleUrls: ['./a-ghost-form.component.scss']
})
export class AGhostFormComponent implements OnInit {

    @Input()
    public size: number = 24;

    constructor() {
    }

    ngOnInit(): void {
    }

}

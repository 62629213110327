<div class="fileDisplay _folder"
     *ngIf="deviceFile.contentType === 'folder'"
     (click)="showImagePopup()"
     [title]="deviceFile.name">
    <div class="grid-x align-middle grid-margin-x">
        <div class="cell auto">
            <div class="fileDisplay-name">{{deviceFile.name}}</div>
        </div>
        <div class="cell shrink">
            <button class="button _iconOnly no-margin-bottom"
                    (click)="$event.stopPropagation(); openDropdown()" type="button"
                    (focusout)="closeDropdown()"
                    data-toggle="quick-menu-dropdown" data-autoclose>
                <i class="fas fa-ellipsis-v"></i>
            </button>

            <div class="dropdown-pane _small"
                 data-alignment="bottom"
                 id="quick-menu-dropdown" data-dropdown #dropdown>
                <!-- <button class="button small expanded _primary">
                    {{'common.download' | translate}}
                </button>-->
                <button class="button small expanded _alert"
                        (click)="$event.stopPropagation(); askIfSureDelete()">
                    {{'common.delete' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="fileDisplay-creationDate">
        {{deviceFile.creationDate | amTimeAgo}}<br/>
    </div>
</div>

<div #image *ngIf="deviceFile.contentType !== 'folder'"
     [class._isHover]="isHovering"
     (mouseenter)="isHovering=true"
     (mouseleave)="isHovering=false"
     class="fileDisplay _file"
     (click)="showImagePopup()">
    <div *ngIf="!removeButton" class="fileDisplay-actions grid-x grid-margin-x">
        <button class="cell auto button _primary _small no-margin-bottom"
                (click)="$event.stopPropagation(); downloadFile();">
            <i class="fas fa-download"></i>
        </button>
        <button class="cell auto button alert _alert _small no-margin-bottom"
                (click)="$event.stopPropagation(); askIfSureDelete();">
            <i class="fas fa-trash"></i>
        </button>
    </div>
    <img [src]="fileUrl">
</div>

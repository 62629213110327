import {Component, OnInit} from '@angular/core';
import {DeviceType} from 'lib-devices';
import {DeviceSearchService} from '../../../services/device-search/device-search.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'm-quick-type-filter',
    templateUrl: './m-quick-type-filter.component.html',
    styleUrls: ['./m-quick-type-filter.component.scss']
})
export class MQuickTypeFilterComponent implements OnInit {

    typesSelected: Array<DeviceType> = [];

    deviceTypes = DeviceType;

    constructor(private deviceSearchService: DeviceSearchService) {
        deviceSearchService.deviceSearchSubject
            .pipe(
                map(deviceSearch => deviceSearch.types),
                map(types => types === undefined ? [] : types)
            )
            .subscribe(types => this.typesSelected = types);

    }


    toggleQuickFilter(type: DeviceType) {
        const index = this.typesSelected.indexOf(type);
        if (index > -1) {
            this.typesSelected.splice(index, 1);
        } else {
            this.typesSelected.push(type);
        }
        this.updateDeviceSearch();
    }

    private updateDeviceSearch() {
        const deviceSearch = this.deviceSearchService.deviceSearchSubject.getValue();
        deviceSearch.types = this.typesSelected;
        this.deviceSearchService.deviceSearchSubject.next(deviceSearch);
    }

    ngOnInit(): void {

    }

}


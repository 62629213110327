import {AfterViewInit, Component, ElementRef, Inject, InjectionToken, OnInit, ViewChild} from '@angular/core';
import {DeviceFile, DeviceFileGroup, DeviceFileUtil, FileHttpService} from 'lib-devices';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Location} from '@angular/common';
import * as Foundation from 'foundation-sites/js/foundation';
import * as $ from 'jquery';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import Swal from 'sweetalert2';

export const TIME_DIFF_SEPARATORS: InjectionToken<Array<number>> = new InjectionToken<Array<number>>('Time Separators for file display in minutes');


@Component({
    selector: 'l-device-tabs-files',
    templateUrl: './l-device-tabs-files.component.html',
    styleUrls: ['./l-device-tabs-files.component.scss'],
})

export class LDeviceTabsFilesComponent implements AfterViewInit, OnInit {


    public files: Array<DeviceFile>;
    private _currentFolderPath: string = '/';
    public currentFolderIsRoot: boolean = true;

    @ViewChild('newFolderPopup')
    public newFolderPopupEl: ElementRef;
    private newFolderPopup: Foundation.Reveal;
    newFolderName: string;
    public fileGroups: Array<DeviceFileGroup>;
    public recentFiles: DeviceFileGroup;
    public currentDepth: number;
    private deviceId: string;

    public fileToPreview: DeviceFile;
    public showingFilePreview: boolean = false;
    private currentPreviewIndex: [number, number];
    previewCanGoBack: boolean;
    previewCanGoForward: boolean;


    constructor(private fileHttpService: FileHttpService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private location: Location,
                private translateService: TranslateService,
                @Inject(TIME_DIFF_SEPARATORS) private timeDiffSeparators: Array<number>) {
    }

    public ngAfterViewInit(): void {
        this.newFolderPopup = new Foundation.Reveal($(this.newFolderPopupEl.nativeElement));
    }

    loadRecentFiles(deviceId): Observable<DeviceFile[]> {
        return this.fileHttpService.getRecentFiles(deviceId).pipe(
            tap(files => this.recentFiles = {key: -2, label: 'files.recent', files})
        );
    }

    ngOnInit(): void {

        this.activatedRoute.queryParamMap.pipe(
            map(paramMap => paramMap.get('path') || 'root'),
            tap(path => this.currentFolderPath = path),
            switchMap(() => this.activatedRoute.url),
            map(url => url[1].path.toString()),
            tap(deviceId => this.deviceId = deviceId),
            switchMap(deviceId => this.loadRecentFiles(deviceId)),
            switchMap(() => this.fileHttpService.getFilesInFolder(this.currentFolderPath, this.deviceId)),
            tap((files: Array<DeviceFile>) =>
                this.fileGroups = DeviceFileUtil.makeDeviceFileGroups(
                    files,
                    this.timeDiffSeparators,
                    this.translateService)
            )
        )
            .subscribe();

        this.updateCurrentFolderPath();
    }

    back() {
        if (!this.currentFolderIsRoot) {
            const currentPathArray = this.currentFolderPath.split('/');
            const newPath = currentPathArray.slice(0, currentPathArray.length - 1).join('/');

            this.router.navigate([], {
                queryParams: {path: newPath},
                preserveFragment: true
            }).then();
        }
    }

    updateCurrentFolderPath(path: string = this.currentFolderPath) {
        this.currentFolderPath = path;
        this.router.navigate([], {queryParams: {path: this.currentFolderPath}, preserveFragment: true}).then();
    }

    fileClick(file: DeviceFile) {
        if (file.contentType === 'folder') {
            this.currentFolderPath = file.path;
            this.updateCurrentFolderPath();
        } else {

        }
    }

    showNewFolderPopup() {
        this.newFolderName = undefined;
        this.newFolderPopup.open();
    }

    reloadFiles() {
        this.fileHttpService.getFilesInFolder(this.currentFolderPath, this.deviceId).subscribe(
            (files: Array<DeviceFile>) =>
                this.fileGroups = DeviceFileUtil.makeDeviceFileGroups(
                    files,
                    this.timeDiffSeparators,
                    this.translateService
                )
        );

        this.loadRecentFiles(this.deviceId).subscribe();
    }

    createNewFolder() {
        const folder: DeviceFile = {
            name: this.newFolderName,
            path: this.currentFolderPath + '/' + this.newFolderName,
            contentType: 'folder'
        };
        this.fileHttpService
            .postFile('', folder, this.deviceId)
            .pipe(
                catchError(err => {
                        if (err.error === 'CONFLICT') {
                            this.showFolderConflictAlert();
                        }
                        return of(err);
                    }
                ),
                tap(() => this.newFolderPopup.close()),
                tap(() => this.reloadFiles())
            ).subscribe();
    }

    renameFile(deviceFileObject: DeviceFile) {
        const deviceFile: DeviceFile = deviceFileObject as DeviceFile;
        const filePathArray = deviceFile.path.split('/');
        const newPath = filePathArray.slice(0, filePathArray.length - 1).join('/') + '/' + deviceFile.name;
        const deviceFilePatch: DeviceFile = {path: newPath};

        this.fileHttpService.patchFile(deviceFilePatch, this.deviceId, deviceFile.id).subscribe();
    }


    downloadFile(deviceFileObject: any) {
        const deviceFile: DeviceFile = deviceFileObject as DeviceFile;
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = '/api/devices/' + this.deviceId + '/' + 'files/' + deviceFile.id;
        a.download = deviceFile.name + '.' + deviceFile.contentType;
        a.click();
    }


    askIfSureDeleteFile(deviceFileObject: any) {
        const deviceFile: DeviceFile = deviceFileObject as DeviceFile;

        const willRecursivelyDeleteText =
            deviceFile.contentType === 'folder' ?
                this.translateService.instant('askIfSureDialogs.willRecursivelyDelete') :
                '';

        const askIfSureDeleteFile = Swal.mixin({
            customClass: {
                confirmButton: 'button _primary',
                cancelButton: 'button _secondary'
            },
            buttonsStyling: true
        });

        askIfSureDeleteFile.fire({
            title: this.translateService.instant('askIfSureDialogs.areYouSure'),
            text: willRecursivelyDeleteText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translateService.instant('common.yes'),
            cancelButtonText: this.translateService.instant('common.cancel'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.deleteFile(deviceFile).pipe(
                    tap(() => this.reloadFiles())
                ).subscribe(
                    (nbFilesDeleted) => {
                        this.closeFilePreview();
                        const title = deviceFile.contentType === 'folder' ?
                            this.translateService.instant('askIfSureDialogs.folderDeleted') :
                            this.translateService.instant('askIfSureDialogs.fileDeleted');

                        const subTitle = (deviceFile.contentType === 'folder' && nbFilesDeleted > 1) ?
                            this.translateService.instant('askIfSureDialogs.filesDeleted', {nbFilesDeleted}) :
                            '';

                        askIfSureDeleteFile.fire(
                            title,
                            subTitle,
                            'success'
                        ).then();
                    }
                );
            }
        });

    }

    deleteFile(file: DeviceFile) {
        return this.fileHttpService.deleteFile(this.deviceId, file.id);
    }

    cancelFolderCreation() {
        this.newFolderPopup.close();
    }

    get currentFolderPath(): string {
        return this._currentFolderPath;
    }

    set currentFolderPath(path: string) {
        this.currentFolderIsRoot = path === 'root';
        this.currentDepth = path.split('/').length - 1;
        this._currentFolderPath = path;
    }


    private showFolderConflictAlert() {
        Swal.fire({
            title: this.translateService.instant('newFolderConflictPopup.title'),
            text: this.translateService.instant('newFolderConflictPopup.alreadyExists', {name: this.newFolderName}),
            icon: 'error',
            confirmButtonText: this.translateService.instant('common.ok')
        }).then();
    }

    showFilePreview(indexObject: any) {
        const index: [number, number] = indexObject as [number, number];
        this.changeCurrentPreviewFileByIndex(index);

        this.showingFilePreview = true;
    }

    getFileUrl(deviceFile: any): string {
        return '/api/devices/' + this.deviceId + '/files/' + deviceFile.id;
    }

    closeFilePreview() {
        this.showingFilePreview = false;
    }

    changeCurrentPreviewFileByRelativeFileIndex(indexChange: number) {
        this.changeCurrentPreviewFileByIndex([this.currentPreviewIndex[0], this.currentPreviewIndex[1] + indexChange]);
    }


    changeCurrentPreviewFileByIndex(newPreviewIndex) {
        let activeFileGroup;


        // Recent files are group index -1
        if (newPreviewIndex[0] === -1) {
            activeFileGroup  = this.recentFiles;
        } else {
            activeFileGroup = this.fileGroups[newPreviewIndex[0]];
        }

        const maxFileIndex = activeFileGroup.files.length - 1;


        if (newPreviewIndex[1] > maxFileIndex || newPreviewIndex[1] < 0) {
            return;
        }

        this.previewCanGoBack = !(newPreviewIndex[1] === 0);
        this.previewCanGoForward = !(newPreviewIndex[1] >= maxFileIndex);
        this.currentPreviewIndex = newPreviewIndex;
        this.fileToPreview = activeFileGroup.files[newPreviewIndex[1]];

    }
}

import {Component, OnInit} from '@angular/core';
import {Device, DeviceHttpService, DeviceStorageService, UserHttpService, UserReference} from 'lib-devices';
import {ActivatedRoute} from '@angular/router';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {DeviceLoggerService} from '../../services/common/device-logger.service';
import {CURRENT_USER} from '../../../../../lib-devices/src/lib/services/storage/storage.keys';
import {noop, of} from 'rxjs';

@Component({
    selector: 'device-view',
    templateUrl: './device.view.html',
    styleUrls: ['./device.view.scss'],
    host: {class: 'cell auto cell-block-y grid-x grid-margin-x'}
})
// tslint:disable-next-line:component-class-suffix
export class DeviceView implements OnInit {

    public device: Device;
    private deviceId: string;
    public users: Array<UserReference>;
    public loadingUsers = true;
    storedUser: UserReference;

    constructor(private activatedRoute: ActivatedRoute,
                public deviceHttpService: DeviceHttpService,
                private deviceStorageService: DeviceStorageService,
                private userHttpService: UserHttpService,
                private deviceLoggerService: DeviceLoggerService) {


    }

    ngOnInit(): void {
        this.storedUser = this.deviceStorageService.get<UserReference>(CURRENT_USER);

        this.userHttpService
            .getUsers()
            .pipe(
                finalize(() => this.loadingUsers = false))
            .subscribe(
                data => this.users = data,
                error => this.deviceLoggerService.warning(error.toString())
            );

        this.renewDeviceFromUrl();


    }

    renewDevice(): void {
        this.storedUser = this.deviceStorageService.get<UserReference>(CURRENT_USER);
        this.deviceHttpService
            .getById(this.deviceId)
            .pipe(
                tap(res => this.device = res),
                switchMap(() => of(noop()))
            ).subscribe(
            () => noop(),
            error => this.deviceLoggerService.warning(error.toString())
        );
    }

    renewDeviceFromUrl() {

        this.activatedRoute.params.pipe(
            tap(params => this.deviceId = params.id),
            map(params => params.id),
            switchMap(deviceId => this.deviceHttpService.getById(deviceId))
        ).subscribe(
            res => this.device = res,
            err => this.deviceLoggerService.warning(err.toString())
        );

        this.storedUser = this.deviceStorageService.get<UserReference>(CURRENT_USER);
    }


}

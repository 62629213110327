import {Component, Input} from '@angular/core';

@Component({
    selector: 'a-space-available-display',
    templateUrl: './a-space-available-display.component.html',
    styleUrls: ['./a-space-available-display.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
})
export class ASpaceAvailableDisplayComponent {

    @Input()
    public spaceAvailable: number;

    @Input()
    public spaceTotal: number;

}

<div class="spaceAvailable-infos">
    <i class="spaceAvailable-icon fas fa-microchip"></i>

    <div>
        {{'label.spaceAvailable' | translate}} :
        <span class="spaceAvailable-values">
            {{spaceAvailable | bytes : 2 : 'B' : 'GB'}}  / {{spaceTotal | bytes : 2 : 'B' : 'GB' }}
        </span>
    </div>
</div>

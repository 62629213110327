import {Component, Input} from '@angular/core';
import {Device, DeviceAvailability} from 'lib-devices';

@Component({
    selector: 'm-no-device',
    templateUrl: './m-no-device.component.html',
    styleUrls: ['./m-no-device.component.scss']
})
export class MNoDeviceComponent {


    public noDevice = {
        type: 'MOBILE',
        os: 'EMPTY',
    };
}


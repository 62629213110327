import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Device, DeviceHttpService, OsBlock, UserReference} from 'lib-devices';
import {DeviceLoggerService} from '../../../services/common/device-logger.service';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';


class OsBlockForm {
    comment: string;
    user: UserReference;
}

@Component({
    selector: 'o-device-block-os-form',
    templateUrl: './o-device-block-os-form.component.html',
    styleUrls: ['./o-device-block-os-form.component.scss']
})

export class ODeviceBlockOsFormComponent {


    private _device: Device;

    private _users: Array<UserReference>;

    private _storedUser: UserReference;

    loadingDevice = true;
    loadingUsers = true;

    showOsBlockForm = false;

    osBlockForm: OsBlockForm = new OsBlockForm();

    @Output()
    renewDevice = new EventEmitter<void>();

    submittingOsBlock = false;


    constructor(private deviceHttpService: DeviceHttpService,
                private translateService: TranslateService,
                private deviceLoggerService: DeviceLoggerService) {

    }

    @Input()
    set device(device: Device) {

        if (device) {
            this.showOsBlockForm = device.osBlock !== null;
            this.submittingOsBlock = false;
            this.loadingDevice = false;
            this._device = device;
        } else {
            this.loadingDevice = true;
        }
    }

    get device() {
        return this._device;
    }

    get users(): Array<UserReference> {
        return this._users;
    }


    @Input()
    set users(users: Array<UserReference>) {
        this.loadingUsers = !users;
        this._users = users;
    }


    get storedUser(): UserReference {
        return this._storedUser;
    }

    @Input()
    set storedUser(user: UserReference) {
        this._storedUser = user;
        this.osBlockForm.user = user;
    }


    onSubmitOsBlock() {

        const user = this.osBlockForm.user;

        this.device.osBlock = new OsBlock(user, this.osBlockForm.comment);
        this.osBlockForm.comment = undefined;
        this.submittingOsBlock = true;

        this.putDeviceAndReload().subscribe();
    }


    cancelOsBlockRequest(): Observable<string> {
        this.device.osBlock = null;
        return this.putDeviceAndReload();
    }

    getNewDeviceAndPushOsBlock() {
        return this.deviceHttpService.getById(this.device.id)
            .pipe(
                map(newDevice => {
                    newDevice.osBlock = this.device.osBlock;
                    return newDevice;
                }),
                switchMap(newDeviceWithOsBlock => this.deviceHttpService.putDevice(newDeviceWithOsBlock))
            );
    }


    putDeviceAndReload(): Observable<string> {
        return this.deviceHttpService.putDevice(this.device)
            .pipe(
                catchError(err => {
                    if (err === 'OUT_OF_SYNC') {
                        return this.getNewDeviceAndPushOsBlock();
                    } else {
                        of(err);
                    }
                }),
                tap(() => this.renewDevice.emit())
            );
    }


    public getOsIcon(os: string): string {
        switch (os) {
            case 'IOS' :
                return 'fab fa-apple';

            case 'ANDROID' :
                return 'fab fa-android';

            case 'WINDOWS' :
                return 'fab fa-windows';

            default :
                return 'fas fa-question';
        }
    }

    askIfSureCancelOsBlock(): void {

        const askIfSureCancelOsBlock = Swal.mixin({
            customClass: {
                confirmButton: 'button _primary',
                cancelButton: 'button _secondary'
            },
            buttonsStyling: true
        });
        askIfSureCancelOsBlock.fire({
            title: this.translateService.instant('askIfSureDialogs.areYouSure'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translateService.instant('common.yes'),
            cancelButtonText: this.translateService.instant('common.cancel'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.cancelOsBlockRequest().subscribe(
                    () => askIfSureCancelOsBlock.fire(
                        this.translateService.instant('askIfSureDialogs.osBlockCanceled'),
                        '',
                        'success'
                    )
                );
            }
        });
    }
}

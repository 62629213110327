<div class="cell shrink">
    <div class="associatedProject-logoContainer">
        <img *ngIf="project.logoUrl" [src]="project.logoUrl" [alt]="project.name">
        <i *ngIf="!project.logoUrl" class="fas fa-folder associatedProject-logoPlaceholder"></i>
    </div>
</div>
<div class="cell auto associatedProject-name">
    {{project.name}}
</div>
<div class="cell shrink associatedProject-removeButton" (click)="removeProject($event)">
    <i class="fas fa-trash"></i>
</div>

<div class="grid-x align-middle">
    <div class="cell shrink">
        <i class="appVersion-icon fas fa-info-circle"></i>
    </div>

    <div class="cell auto">
        <div>
            {{'version.app' | translate}} :
            <span class="appVersion-value">
                {{appVersion}}
            </span>
        </div>
    </div>
</div>

<div class="cell shrink grid-y show-for-desktop">
    <a-button-back class="cell shrink" [routerLink]="['/']" [labelKey]="'button.returnToDeviceList'"></a-button-back>
    <o-quick-access-sidebar class="cell auto" [device]="device"></o-quick-access-sidebar>
</div>

<div class="cell auto grid-y">
    <o-device-res-header class="cell shrink" [device]="device"></o-device-res-header>

    <div class="cell auto reservationContainer grid-y">
        <m-tabs class="cell auto grid-y reservationContainer-tabs">
            <a-tab class="cell auto cell-block-y"
                   title='Infos'
                   fragment="information"
                   [icon]="'fas fa-info-circle'">
                <l-device-tabs-information
                    (renewDevice)="renewDevice()"
                    [device]="device"
                    [users]="users"
                    [storedUser]="storedUser"></l-device-tabs-information>
            </a-tab>
            <a-tab class="cell auto cell-block-y"
                   title="Réservation"
                   fragment="reservation"
                   [icon]="'far fa-calendar-alt'">
                <l-device-tabs-planning
                    (renewDevice)="renewDevice()"
                    [device]="device"
                    [storedUser]="storedUser"></l-device-tabs-planning>
            </a-tab>
            <a-tab class="cell auto cell-block-y"
                   title="Fichiers"
                   fragment="files"
                   [icon]="'far fa-file'">
                <l-device-tabs-files></l-device-tabs-files>
            </a-tab>
        </m-tabs>
    </div>
</div>

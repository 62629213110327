import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MomentModule} from 'ngx-moment';
import {SpacerComponent} from './spacer/spacer.component';

import {ADevicePreviewComponent} from './Atoms/a-device-preview/a-device-preview.component';
import {ASectionTitleComponent} from './Atoms/a-section-title/a-section-title.component';
import {ADeviceNameComponent} from './Atoms/a-device-name/a-device-name.component';
import {AButtonBackComponent} from './Atoms/buttons/a-button-back/a-button-back.component';
import {AButtonDefaultComponent} from './Atoms/buttons/a-button-default/a-button-default.component';
import {AButtonSubmitComponent} from './Atoms/buttons/a-button-submit/a-button-submit.component';
import {AButtonCancelComponent} from './Atoms/buttons/a-button-cancel/a-button-cancel.component';
import {AButtonDeleteComponent} from './Atoms/buttons/a-button-delete/a-button-delete.component';
import {AAlertMessageComponent} from './Atoms/a-alert-message/a-alert-message.component';
import {AUserCommentComponent} from './Atoms/a-user-comment/a-user-comment.component';
import {AAssociatedProjectComponent} from './Atoms/a-associated-project/a-associated-project.component';
import {ARadioButtonComponent} from './Atoms/a-radio-button/a-radio-button.component';
import {AReservationTimeLeftComponent} from './Atoms/a-reservation-time-left/a-reservation-time-left.component';
import {AUserDisplayComponent} from './Atoms/a-user-display/a-user-display.component';
import {ABatteryDisplayComponent} from './Atoms/a-battery-display/a-battery-display.component';
import {ADeviceTypeSelectComponent} from './Atoms/a-device-type-select/a-device-type-select.component';
import {AOsSelectComponent} from './Atoms/a-os-select/a-os-select.component';
import {ASyncDisplayComponent} from './Atoms/a-sync-display/a-sync-display.component';
import {AUserSelectComponent} from './Atoms/a-user-select/a-user-select.component';
import {AOsLabelComponent} from './Atoms/a-os-label/a-os-label.component';
import {AAppVersionComponent} from './Atoms/a-app-version/a-app-version.component';

import {MDeviceComponent} from './Molecules/m-device/m-device.component';
import {MSearchbarComponent} from './Molecules/m-searchbar/m-searchbar.component';
import {MNoDeviceComponent} from './Molecules/m-no-device/m-no-device.component';
import {MQuickOsFilterComponent} from './Molecules/m-quick-os-filter/m-quick-os-filter.component';
import {MQuickTypeFilterComponent} from './Molecules/m-quick-type-filter/m-quick-type-filter.component';
import {MQuickAvailabilityFilterComponent} from './Molecules/m-quick-availability-filter/m-quick-availability-filter.component';

import {ODevicesContainerComponent} from './Organisms/o-devices-container/o-devices-container.component';
import {OQuickFiltersComponent} from './Organisms/o-quick-filters/o-quick-filters.component';

import {NgSelectModule} from '@ng-select/ng-select';
import {DpDatePickerModule} from 'ng2-date-picker';
import {AGhostFormComponent} from './Atoms/a-ghost-form/a-ghost-form.component';
import {AApiInfoDisplayComponent} from './Atoms/a-api-info-display/a-api-info-display.component';
import {ADatePickerComponent} from './Atoms/a-date-picker/a-date-picker.component';
import {AButtonTextComponent} from './Atoms/buttons/a-button-text/a-button-text.component';
import {AQuickFilterButtonComponent} from './Atoms/a-quick-filter-button/a-quick-filter-button.component';
import {ATabComponent} from './Atoms/a-tab/a-tab.component';
import {MTabsComponent} from './Molecules/m-tabs/m-tabs.component';
import {ASpaceAvailableDisplayComponent} from './Atoms/a-space-available-display/a-space-available-display.component';
import {NgBytesPipeModule} from 'angular-pipes';
import {AFileDisplayComponent} from './Atoms/a-file-display/a-file-display.component';
import {ASmallFolderDisplayComponent} from './Atoms/a-small-folder-display/a-small-folder-display.component';
import {MPathDisplayComponent} from './Molecules/m-path-display/m-path-display.component';
import {MFilePreviewComponent} from './Molecules/m-file-preview/m-file-preview.component';
import {ANoFolderComponent} from './Atoms/a-no-folder/a-no-folder.component';
import {ANoFilesComponent} from './Atoms/a-no-files/a-no-files.component';


const components = [
    SpacerComponent,
    AApiInfoDisplayComponent,
    ADevicePreviewComponent,
    ASectionTitleComponent,
    ADeviceNameComponent,
    AButtonBackComponent,
    AButtonDefaultComponent,
    AButtonSubmitComponent,
    AButtonCancelComponent,
    AButtonDeleteComponent,
    AAlertMessageComponent,
    AGhostFormComponent,
    AFileDisplayComponent,
    AUserCommentComponent,
    AAssociatedProjectComponent,
    ARadioButtonComponent,
    AReservationTimeLeftComponent,
    AUserDisplayComponent,
    ABatteryDisplayComponent,
    ADeviceTypeSelectComponent,
    ASmallFolderDisplayComponent,
    AOsSelectComponent,
    ASyncDisplayComponent,
    AOsLabelComponent,
    AQuickFilterButtonComponent,
    ATabComponent,
    ANoFolderComponent,
    ANoFilesComponent,
    MTabsComponent,
    MFilePreviewComponent,
    MDeviceComponent,
    MSearchbarComponent,
    MNoDeviceComponent,
    MQuickOsFilterComponent,
    MQuickTypeFilterComponent,
    MPathDisplayComponent,
    MQuickAvailabilityFilterComponent,
    ODevicesContainerComponent,
    OQuickFiltersComponent,
    ADeviceTypeSelectComponent,
    AOsSelectComponent,
    AUserSelectComponent,
    ASpaceAvailableDisplayComponent,
    AAppVersionComponent
];

@NgModule({
    declarations: [
        components,
        ADatePickerComponent,
        AButtonTextComponent,
        MFilePreviewComponent
    ],
    exports: [
        components,
        ADatePickerComponent
    ]
    ,
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        MomentModule,
        NgSelectModule,
        DpDatePickerModule,
        NgBytesPipeModule
    ]
})
export class DeviceComponentsModule {
}

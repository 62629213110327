<ng-container>
    <a-ghost-form *ngIf="loadingDevice"></a-ghost-form>
    <ng-container>
        <a-section-title [hidden]="loadingDevice" [faIconClass]="getOsIcon(device?.os)">
            {{device?.os && 'OsType.' + device?.os | translate}} {{device?.osVersion}}
            <i *ngIf="device?.osBlock" class="fas fa-lock"></i>
        </a-section-title>

        <ng-container *ngIf="device?.osBlock==null || submittingOsBlock">
            <div [hidden]="loadingDevice" class="text-center" *ngIf="!showOsBlockForm">
                <span class="font-italic">{{'label.osNotBlocked' | translate}}</span>
                <spacer [size]="16"></spacer>
                <a-button-default (click)="showOsBlockForm = true"
                                  [labelKey]="'label.askForOsBlock'"></a-button-default>
            </div>
            <ng-container *ngIf="showOsBlockForm || submittingOsBlock">
                <form [hidden]="loadingDevice" (ngSubmit)="onSubmitOsBlock()">

                    {{'label.yourOsBlockRequest'| translate}}

                    <spacer [size]="16"></spacer>

                    <label>
                        <span class="formLabel">{{'label.user' | translate}}</span>
                    </label>


                    <a-user-select [(ngModel)]="osBlockForm.user" name="user"></a-user-select>


                    <spacer [size]="16"></spacer>

                    <label>
                    <span class="formLabel">
                        {{'label.comment' | translate}}<br>
                    </span>

                        <textarea [(ngModel)]="osBlockForm.comment" name="comment" rows="4"
                                  placeholder="{{'placeholder.osBlockComment' | translate}}"></textarea>
                    </label>

                    <div class="grid-x grid-margin-x align-center">
                        <div class="cell shrink">
                            <a-button-cancel (click)="showOsBlockForm = false"
                                             [labelKey]="'common.cancel'"></a-button-cancel>
                        </div>
                        <div class="cell shrink">
                            <a-button-submit [labelKey]="'button.validate'"
                                             [loading]="submittingOsBlock"
                                             [disabled]="((this.osBlockForm.user === null &&
                                                        this.storedUser === null ) ||
                                                        (this.osBlockForm.comment==='' ||
                                                        this.osBlockForm.comment===undefined))">

                            </a-button-submit>
                        </div>
                    </div>
                </form>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!loadingDevice && !submittingOsBlock && device.osBlock">

        <div class="grid-x align-center">
            <a-alert-message class="cell shrink">
                {{'label.doNotUpdate' | translate}}
            </a-alert-message>
        </div>

        <spacer [size]="16"></spacer>

        <a-user-comment [userName]="device.osBlock.userReference.userName">
            {{device.osBlock.comment}}
        </a-user-comment>

        <spacer [size]="16"></spacer>

        <div class="grid-x grid-margin-x align-center">
            <div class="cell shrink">
                <a-button-cancel (click)="askIfSureCancelOsBlock()"
                                 [labelKey]="'button.cancelOsBlockRequest'"></a-button-cancel>
            </div>
        </div>
    </ng-container>
</ng-container>

import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Device} from 'lib-devices';

@Component({
    selector: 'a-device-preview',
    templateUrl: './a-device-preview.component.html',
    styleUrls: ['./a-device-preview.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-y'}
})
export class ADevicePreviewComponent implements OnInit, OnChanges {

    @Input()
    public device: Device;

    // Type host binding
    @HostBinding('class._phone') typeMobile: boolean = false;
    @HostBinding('class._tablet') typeTablet: boolean = false;

    // os host binding
    @HostBinding('class._ios') osApple: boolean = false;
    @HostBinding('class._android') osAndroid: boolean = false;
    @HostBinding('class._windows') osWindows: boolean = false;

    ngOnInit() {
        this.setDevicePreview();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setDevicePreview();
    }

    public getOsIcon(os: string) {
        switch (os) {
            case 'IOS' :
                return 'fab fa-apple';

            case 'ANDROID' :
                return 'fab fa-android';

            case 'WINDOWS' :
                return 'fab fa-windows';

            case 'EMPTY' :
                return 'fas fa-sad-tear';

            default :
                return 'fas fa-question';
        }
    }

    private setDevicePreview() {
        // Reset value
        this.typeMobile = false;
        this.typeTablet = false;
        this.osApple = false;
        this.osAndroid = false;
        this.osWindows = false;

        switch (this.device.type) {
            case 'MOBILE' :
                this.typeMobile = true;
                break;

            case 'TABLET' :
                this.typeTablet = true;
                break;
        }

        switch (this.device.os) {
            case 'IOS' :
                this.osApple = true;
                break;

            case 'ANDROID' :
                this.osAndroid = true;
                break;

            case 'WINDOWS' :
                this.osWindows = true;
                break;
        }
    }
}

import {Component} from '@angular/core';


@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'appHeader grid-x grid-padding-x grid-padding-y align-middle'}
})
export class AppHeaderComponent {

}


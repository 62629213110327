import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DeviceFile} from 'lib-devices';
import * as Foundation from 'foundation-sites/js/foundation';
import * as $ from 'jquery';
import {of} from 'rxjs';
import {delay, tap} from 'rxjs/operators';

@Component({
    selector: 'a-file-display',
    templateUrl: './a-file-display.component.html',
    styleUrls: ['./a-file-display.component.scss']
})
export class AFileDisplayComponent implements AfterViewInit {

    @ViewChild('dropdown')
    public dropdownRef: ElementRef;
    private dropdown: Foundation.Dropdown;

    @ViewChild('image')
    private image: ElementRef;

    @Input()
    deviceFile: DeviceFile;

    @Input()
    public fileUrl: string;

    @Input()
    index: [number, number];

    @Output()
    public askIfSureDeleteEmitter: EventEmitter<DeviceFile> = new EventEmitter<DeviceFile>();

    @Output()
    public showFilePreviewEmitter: EventEmitter<[number, number]> = new EventEmitter<[number, number]>();

    @Output()
    public downloadFileEmitter: EventEmitter<DeviceFile> = new EventEmitter<DeviceFile>();

    @Output()
    public reloadFiles: EventEmitter<void> = new EventEmitter<void>();


    isHovering: boolean = false;
    removeButton: boolean = false;


    constructor() {
    }


    showImagePopup() {
        if (this.deviceFile.contentType !== 'folder') {
            this.showFilePreviewEmitter.emit(this.index);
        }
    }

    askIfSureDelete() {
        this.removeButton = true;
        of(null).pipe(
            delay(200),
            tap(() => {
                this.removeButton = false;
                this.isHovering = false;
            })
        ).subscribe();

        this.askIfSureDeleteEmitter.emit(this.deviceFile);

    }

    downloadFile() {
        this.downloadFileEmitter.emit(this.deviceFile);
    }

    public ngAfterViewInit(): void {
        try {
            this.dropdown = new Foundation.Dropdown($(this.dropdownRef.nativeElement));
        } catch {
            //not a folder
        }
    }

    public openDropdown(): void {
        this.dropdown.toggle();
    }

    closeDropdown() {
        setTimeout(() => this.dropdown.close(), 100);

    }
}

import {Component, forwardRef, OnInit} from '@angular/core';
import {DeviceHttpService} from 'lib-devices';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
    selector: 'a-os-select',
    templateUrl: './a-os-select.component.html',
    styleUrls: ['./a-os-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AOsSelectComponent),
        }
    ]

})
export class AOsSelectComponent implements OnInit, ControlValueAccessor {
    private changeSelectedOs: (string) => void;
    public placeholder: string;

    get selectedOs(): string {
        return this._selectedOs;
    }

    set selectedOs(value: string) {
        if (this.changeSelectedOs !== undefined) {
            this.changeSelectedOs(value);
        }


        this._selectedOs = value;
    }


    public oss$: Observable<Array<IOption>>;

    public loading: boolean;

    private _selectedOs: string = undefined;


    constructor(private deviceHttpService: DeviceHttpService) {


    }

    ngOnInit(): void {

        this.oss$ = this.deviceHttpService.getOSs().pipe(map(osArray => osArray.map(
            os => {
                return {
                    label: os,
                    value: os
                };
            })));
    }

    registerOnChange(fn: any): void {
        this.changeSelectedOs = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(os: string): void {
        if (os !== null) {
            if (os) {
                this.selectedOs = os;
            }
        }
    }
}


interface IOption {
    label: string;
    value: any;
    disabled?: boolean;
}

import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';


import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {ServicesModule} from './services/services.module';
import {createTranslateLoader, getLocaleId} from './services/i18n/i18n.function';
import {FRONT_ORIGIN, LibDevicesModule} from 'lib-devices';
import {DeviceComponentsModule} from './components/components.module';
import {ViewsModule} from './views/views.module';
import {RootComponent} from './views/root.component';
import {DeviceModule} from './views/device/device.module';
import {FrontOrigin} from '../../../lib-devices/src/lib/domain/front-origin.model';
import {
    SENTRY_PROJECT,
    SENTRY_TOKEN,
    SharedDevicesErrorReportingService
} from './services/error-handling/shared-devices-error-reporting.service';
import {TIME_DIFF_SEPARATORS} from './views/device/tabs/l-device-tabs-files/l-device-tabs-files.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';


const components = [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ServicesModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    LibDevicesModule,
    DeviceComponentsModule,
    ViewsModule,
    DeviceModule,
    SweetAlert2Module.forRoot()
];

@NgModule({
    imports: components,
    providers: [
        {
            provide: ErrorHandler,
            useClass: SharedDevicesErrorReportingService
        },
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (getLocaleId)
        },
        {
            provide: FRONT_ORIGIN,
            useValue: FrontOrigin.USER
        },
        {
            provide: SENTRY_PROJECT,
            useValue: '4shared-devices-user'
        },
        {
            provide: SENTRY_TOKEN,
            useValue: 'https://0b77b565983943c5a1283817c458c93f@o281892.ingest.sentry.io/5236129'
        },
        {
            provide: TIME_DIFF_SEPARATORS,
            useValue: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
        }]
    ,
    bootstrap: [RootComponent]
})
export class AppModule {
}

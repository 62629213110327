import {Component, Input, OnInit} from '@angular/core';
import {Device, DeviceType} from 'lib-devices';

@Component({
    selector: 'o-devices-container',
    templateUrl: './o-devices-container.component.html',
    styleUrls: ['./o-devices-container.component.scss'],
    host: {class: 'grid-x grid-margin-x grid-margin-y phone-up-1 tablet-portrait-up-2 tablet-landscape-up-3 desktop-up-4 big-desktop-up-5'}

})
export class ODevicesContainerComponent implements OnInit {

    private ghostDevices: Device[];

    get devices(): Array<Device> {
        return this._devices;
    }

    @Input()
    set devices(devices: Array<Device>) {
        if (devices !== null) {
            this._devices = devices;
            this.loadingDevices = false;
        } else {
            this.devices = this.ghostDevices;
            this.loadingDevices = true;
        }
    }

    private _devices: Array<Device>;

    loadingDevices: boolean = true;

    constructor() {


    }

    private getGhostDevices(nbDevices: number) {
        return Array<Device>(nbDevices).fill({
            associatedProjects: undefined,
            availability: undefined,
            batteryPercentage: 0,
            currentUser: {userId: '', userName: undefined, userCode: ''},
            lastNativeSyncDate: undefined,
            model: '',
            name: ' ‎',
            os: '',
            osBlock: undefined,
            osVersion: '',
            spaceAvailable: 0,
            spaceTotal: 0,
            type: DeviceType.MOBILE,
            id: '',
            appVersion: '0.0.0',
            uuid: ''
        });
    }

    ngOnInit(): void {
        this.ghostDevices = this.getGhostDevices(13);
        this._devices = this.ghostDevices;
        this.loadingDevices = true;
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {Device, DeviceHttpService, DeviceType} from 'lib-devices';
import {finalize} from 'rxjs/operators';
import {DeviceLoggerService} from '../../../services/common/device-logger.service';

@Component({
    selector: 'o-quick-access-sidebar',
    templateUrl: './o-quick-access-sidebar.component.html',
    styleUrls: ['./o-quick-access-sidebar.component.scss'],
    host: {class: 'grid-y'}
})
export class OQuickAccessSidebarComponent implements OnInit {


    devices: Array<Device>;
    loadingDevices = true;

    private ghostDevices: Array<Device>;
    private _device: Device;

    constructor(private deviceHttpService: DeviceHttpService,
                private deviceLoggerService: DeviceLoggerService) {

    }

    @Input()
    set device(device: Device) {
        if (!this._device || (device && this._device.id !== device.id)) {
            this.loadingDevices = true;
            this.devices = this.ghostDevices;
            if (device) {
                this.deviceHttpService
                    .getSimilar(device.id)
                    .pipe(
                        finalize(() => this.loadingDevices = false)
                    )
                    .subscribe(
                        res => this.devices = res,
                        error => this.deviceLoggerService.warning(error.toString())
                    );
            } else {
                this.loadingDevices = true;
            }
        } else {
            this.loadingDevices = false;
        }
        this._device = device;
    }

    private getGhostDevices(nbDevices: number) {
        return Array<Device>(nbDevices).fill({
            associatedProjects: undefined,
            availability: undefined,
            batteryPercentage: 0,
            currentUser: {userId: '', userName: undefined, userCode: ''},
            lastNativeSyncDate: undefined,
            model: '',
            name: '_‎',
            os: '',
            osBlock: undefined,
            osVersion: '',
            spaceAvailable: 0,
            spaceTotal: 0,
            type: DeviceType.MOBILE,
            id: '',
            appVersion: '0.0.0',
            uuid: ''
        });
    }

    ngOnInit(): void {
        this.loadingDevices = true;
        this.ghostDevices = this.getGhostDevices(4);
        this.devices = this.ghostDevices;
    }
}

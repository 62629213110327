import {Component} from '@angular/core';

@Component({
    selector: 'a-no-folder',
    templateUrl: './a-no-folder.component.html',
    styleUrls: ['./a-no-folder.component.scss']
})
export class ANoFolderComponent {

}


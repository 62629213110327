import {Injectable} from '@angular/core';
import {Device, DeviceHttpService, DeviceSearch} from 'lib-devices';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceLoggerService} from '../common/device-logger.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DeviceSearchService {


    public devices: Device[];

    deviceSearchSubject: BehaviorSubject<DeviceSearch> = new BehaviorSubject({});

    constructor(private location: Location,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private deviceHttpService: DeviceHttpService,
                private deviceLoggerService: DeviceLoggerService) {

        const deviceUpdateObservable: Observable<Device[]> = this.deviceSearchSubject.pipe(
            tap(deviceSearch => this.updateUrl(deviceSearch)),
            switchMap(deviceSearch => this.search(deviceSearch)),
            tap(devices => this.devices = devices)
        );

        this.activatedRoute.url.pipe(tap(() => console.log('checkingUrl'))).subscribe(() => {
            this.initialiseDeviceSearch();
            deviceUpdateObservable.subscribe();
        });

    }

    makeArray(element) {
        return element === undefined ? undefined : Array.isArray(element) ? element : [element];
    }


    initialiseDeviceSearch() {

        const params = this.activatedRoute.snapshot.queryParams;

        const deviceSearch: DeviceSearch = {};
        deviceSearch.modelOrName = params.modelOrName;
        deviceSearch.types = this.makeArray(params.types);
        deviceSearch.availabilities = this.makeArray(params.availabilities);
        deviceSearch.oss = this.makeArray(params.oss);
        this.deviceSearchSubject.next(deviceSearch);
    }

    makeParams(currentParams, deviceSearch) {
        const params = {};

        for (const prop in deviceSearch) {

            if (deviceSearch[prop] !== undefined) {
                params[prop] = deviceSearch[prop];
            } else if (currentParams[prop] !== undefined) {
                params[prop] = currentParams[prop];
            }
        }

        return params;
    }

    updateUrl(deviceSearch) {
        const params = this.makeParams(this.activatedRoute.snapshot.queryParams, deviceSearch);

        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: params
            }).then();
    }

    search(deviceSearch): Observable<Device[]> {
        return this.deviceHttpService
            .getBySearchInfo(deviceSearch);

    }


}

import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import {Event, EventHint} from '@sentry/types';
import {HttpErrorResponse} from '@angular/common/http';
import {SharedDevicesAppInfo} from 'lib-devices';
import * as Sentry from '@sentry/browser';


export const SENTRY_TOKEN = new InjectionToken<string>('Sentry token');
export const SENTRY_PROJECT = new InjectionToken<string>('Sentry project');

@Injectable()
export class SharedDevicesErrorReportingService implements ErrorHandler {

    constructor(@Inject(SENTRY_TOKEN) private token: string,
                @Inject(SENTRY_PROJECT) private project: string) {
    }

    private static shouldReportEvent(hint: EventHint): boolean {
        if (hint && hint.originalException) {
            if (hint.originalException instanceof HttpErrorResponse) {
                console.log('[Sentry.beforeSend] Dropping event. HttpErrorResponse will not be reported:', hint.originalException.message);
                return false;
            } else {
                console.log('[Sentry.beforeSend] Reporting Event', hint.originalException);
            }
        }
        return true;
    }


    startReporting(appInfo: SharedDevicesAppInfo): void {

        const sentryOptions = {
            dsn: this.token,
            blacklistUrls: [],
            release: `${this.project}@${appInfo.releaseName}`,
            beforeSend: (event: Event, hint?: EventHint) => {
                if (SharedDevicesErrorReportingService.shouldReportEvent(hint)) {
                    return event;
                }

                // Do not report server side errors to Sentry
                return null;
            }
        };

        console.log(`--- [SharedDevicesErrorReportingService] Starting Sentry with release name '${appInfo.releaseName}'... ---`,
            sentryOptions);
        Sentry.init(sentryOptions);
    }

    handleError(error: any): void {
        const eventId = Sentry.captureException(error.originalError || error);
    }


}

<div class="device-card grid-y">
    <div class="cell auto grid-x grid-padding-y grid-padding-x">
        <a-device-preview class="cell shrink" [device]="noDevice"></a-device-preview>

        <div class="cell auto">
            <div class="device-name">{{'noDevice.title' | translate}}</div>
        </div>
    </div>
    <div class="cell auto">
        <div class="device-explain">
            {{'noDevice.explain' | translate}}
        </div>
    </div>
</div>

<div class="reserve" *ngIf="!loadingDevice && device.availability==deviceAvailability.AVAILABLE">
    <form #form="ngForm">

        <a-section-title [faIconClass]="'fas fa-bookmark'" [labelKey]="'label.reservation'"></a-section-title>

        <div class="grid-x grid-margin-x">
            <a-radio-button class="cell phone-12 tablet-portrait-12 tablet-landscape-8 desktop-8 big-desktop-shrink"
                            [label]="'button.1h'"
                            name="time"
                            [(ngModel)]="reservationEndTime"
                            (click)="selectRadioButton()"
                            [value]="reservationTypes.ONE_HOUR"></a-radio-button>

            <a-radio-button class="cell phone-12 tablet-portrait-12 tablet-landscape-8 desktop-8 big-desktop-shrink"
                            [label]="'button.2h'"
                            [(ngModel)]="reservationEndTime"
                            (click)="selectRadioButton()"
                            name="time"
                            [value]="reservationTypes.TWO_HOURS"></a-radio-button>

            <a-radio-button class="cell phone-12 tablet-portrait-12 tablet-landscape-8 desktop-8 big-desktop-auto"
                            *ngIf="!isAfternoon()"
                            [(ngModel)]="reservationEndTime"
                            [label]="'button.morning'"
                            name="time"
                            (click)="selectRadioButton()"
                            [value]="reservationTypes.MORNING"></a-radio-button>

            <a-radio-button class="cell phone-12 tablet-portrait-12 tablet-landscape-12 desktop-8 big-desktop-auto"
                            [label]="(!isAfternoon() ? 'button.day' : 'button.afternoon')"
                            [(ngModel)]="reservationEndTime"
                            name="time"
                            (click)="selectRadioButton()"
                            [value]="reservationTypes.DAY"></a-radio-button>

            <a-radio-button class="cell phone-12 tablet-portrait-12 tablet-landscape-12 desktop-8 big-desktop-auto"
                            [label]="'button.untilDate'"
                            [(ngModel)]="reservationEndTime"
                            name="time"
                            (click)="selectRadioButton()"
                            [value]="reservationTypes.BY_END_DATE"></a-radio-button>
        </div>

        <spacer size="16"></spacer>

        <label *ngIf="reservationEndTime === reservationTypes.BY_END_DATE || reservationEndDate">
            <span class="formLabel">
                {{'common.untilDateExplain' | translate}}
            </span>

            <a-date-picker [required]="true"
                           [(ngModel)]="reservationEndDate"
                           [minDate]="tomorrow"
                           name="reservationEndDate"></a-date-picker>
        </label>

        <spacer size="16"></spacer>

        <label>
            <span class="formLabel">
                {{'label.user' | translate}}
            </span>

            <a-user-select [(ngModel)]="selectedUser" name="user"></a-user-select>
        </label>


        <spacer size="16"></spacer>

        <div class="grid-x align-center">
            <div class="cell shrink">
                <a-button-submit [labelKey]="'button.reserve'"
                                 (click)="onSubmitReservation()"
                                 [loading]="pushingReservation"
                                 [disabled]="reservationFormIsValid()"></a-button-submit>
            </div>
        </div>
    </form>
</div>

<div class="reserve" *ngIf="!loadingDevice && !loadingReservation && device.availability==deviceAvailability.TAKEN">
    <a-section-title [faIconClass]="'fas fa-bookmark'">
        {{'label.reservedBy' | translate:{user: currentReservation?.userReference.userName} }}
    </a-section-title>

    <a-reservation-time-left [time]="currentReservation?.end | amCalendar"></a-reservation-time-left>

    <spacer size="16"></spacer>

    <div class="grid-x align-center grid-margin-x">
        <div class="cell shrink">
            <a-button-cancel (click)="askIfSureCancelReservation()"
                             labelKey="button.cancelReservation"
                             [loading]="cancelingReservation">

            </a-button-cancel>
        </div>
        <div class="cell shrink">
            <a-button-cancel *ngIf="currentReservation?.end | amIsBefore: tomorrow.hours(0).minutes(0)"
                             (click)="extendReservation(1)"
                             [loading]="extendingReservation"
                             labelKey="button.extend1h"></a-button-cancel>
        </div>
    </div>
</div>

<a-ghost-form *ngIf="loadingDevice"></a-ghost-form>



import {Component, Input} from '@angular/core';
import {Moment} from 'moment';

@Component({
    selector: 'a-sync-display',
    templateUrl: './a-sync-display.component.html',
    styleUrls: ['./a-sync-display.component.scss'],
    host: {class: 'grid-x align-middle'}
    // tslint:disable-next-line:no-host-metadata-property
})
export class ASyncDisplayComponent {

    @Input()
    date: Moment;


}

import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';
import {IDatePickerConfig, ISelectionEvent, SingleCalendarValue} from 'ng2-date-picker';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'a-date-picker',
    templateUrl: './a-date-picker.component.html',
    styles: [':host {display: block}'],
    styleUrls: ['./a-date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ADatePickerComponent),
            multi: true
        }
    ]
})
export class ADatePickerComponent implements ControlValueAccessor {

    public config: IDatePickerConfig = {
        openOnFocus: false,
        firstDayOfWeek: 'mo',
        unSelectOnClick: false,
        closeOnSelectDelay: 0,
        min: this.minDate,
        max: this.maxDate,
        locale: 'fr',
        format: 'DD/MM/YYYY'
    };

    @Input()
    public required: boolean;

    @Input()
    public disabled: boolean = false;

    public realDate;

    @Input()
    placeholder: string;
    private onNgChange: (instant: Moment) => void;
    private onNgTouched: () => void;
    private _maxDate: string;
    private _minDate: string;
    private defaultPlaceholder: string;

    constructor(translateService: TranslateService) {
        translateService.get('date.placeholder')
            .subscribe(label => this.defaultPlaceholder = label);
    }

    @Input()
    public get maxDate(): string {
        return this._maxDate;
    }

    public set maxDate(maxDate: string) {
        this._maxDate = maxDate;
        this.config = {
            ...this.config,
            max: maxDate
        };
    }

    @Input()
    public get minDate(): string {
        return this._minDate;
    }

    public set minDate(minDate: string) {
        this._minDate = minDate;
        this.config = {
            ...this.config,
            min: minDate
        };
    }

    public getPlaceholder(): string {
        return this.placeholder === undefined ? this.defaultPlaceholder : this.placeholder;
    }

    public onChange(event: ISelectionEvent): void {
        const date = this.computeDate(event.date);
        if (!areDateEquals(date, this.realDate)) {
            this.realDate = date;
            if (date === undefined) {
                this.onNgChange(undefined);
            } else {
                this.onNgChange(date);
            }
        }
    }

    private computeDate(date: SingleCalendarValue): Moment {
        if (moment.isMoment(date)) {
            return date as Moment;
        } else {
            const m = moment(date, ['DD-MM-YYYY', 'DD/MM/YYYY']);
            if (m.isValid()) {
                return m;
            } else {
                return undefined;
            }
        }
    }

    public onOpen(): void {
        this.onNgTouched();
    }

    public writeValue(obj: Moment): void {

        if (obj !== null) {
            this.realDate = obj === undefined ? undefined : obj;
        } else {
            this.realDate = null;
        }
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }
}

function areDateEquals(date1?: Moment, date2?: Moment): boolean {
    if (!date1) {
        return !date2;
    }
    if (!date2) {
        return !date1;
    }
    return date1.isSame(date2);
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ODeviceBlockOsFormComponent} from './o-device-block-os-form/o-device-block-os-form.component';
import {ODeviceResFormComponent} from './o-device-res-form/o-device-res-form.component';
import {ODeviceResHeaderComponent} from './o-device-res-header/o-device-res-header.component';
import {TranslateModule} from '@ngx-translate/core';
import {OQuickAccessSidebarComponent} from './o-quick-access-sidebar/o-quick-access-sidebar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeviceView} from './device.view';
import {RouterModule} from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MomentModule} from 'ngx-moment';
import {DeviceComponentsModule} from '../../components/components.module';
import {LDeviceTabsInformationComponent} from './tabs/l-device-tabs-informations/l-device-tabs-information.component';
import {LDeviceTabsPlanningComponent} from './tabs/l-device-tabs-planning/l-device-tabs-planning.component';
import {LDeviceTabsFilesComponent} from './tabs/l-device-tabs-files/l-device-tabs-files.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        MomentModule,
        DeviceComponentsModule
    ],
    declarations: [
        DeviceView,
        ODeviceBlockOsFormComponent,
        ODeviceResFormComponent,
        ODeviceResHeaderComponent,
        OQuickAccessSidebarComponent,
        LDeviceTabsInformationComponent,
        LDeviceTabsPlanningComponent,
        LDeviceTabsFilesComponent
    ]
})
export class DeviceModule {
}

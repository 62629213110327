import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'a-radio-button',
    templateUrl: './a-radio-button.component.html',
    styleUrls: ['./a-radio-button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ARadioButtonComponent),
            multi: true
        }
    ]
})
export class ARadioButtonComponent implements ControlValueAccessor {


    private onNgChange: (value: boolean) => void;
    private onNgTouched: () => void;

    @Input()
    public disabled: boolean;


    private _checked: boolean;

    @Input()
    public label: string;

    @Input()
    public value: any;

    @Input()
    public name: string;

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(obj: boolean): void {
        this._checked = obj;
    }

    public onCheckedChange(checked: boolean): void {
        this.onNgChange(checked);
        this._checked = checked;
    }

    @Input()
    set checked(checked: boolean) {
        this.onNgChange(checked);
        this._checked = checked;
    }

    get checked(): boolean {

        return this._checked;
    }
}

import {Component} from '@angular/core';

@Component({
    selector: 'o-quick-filters',
    templateUrl: './o-quick-filters.component.html',
    styleUrls: ['./o-quick-filters.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-x align-middle grid-margin-x'}
})
export class OQuickFiltersComponent {

}

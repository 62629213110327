<div class="cell shrink">
    <a-section-title [faIconClass]="'fas fa-mobile-alt'" [labelKey]="'label.quickAccess'"></a-section-title>
</div>
<div class="cell auto grid-y">
    <div class="cell cell-block-y auto">
        <ng-container *ngIf="devices.length>0">
            <div [class.ghost]="loadingDevices">
                <m-device *ngFor="let device of devices" [device]="device" [isGhost]="loadingDevices"></m-device>
            </div>
        </ng-container>
    </div>
</div>

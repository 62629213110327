<ng-select #selectField
           [items]="users"
           bindLabel="userName"
           bindValue="userId"
           notFoundText="{{'label.notFound' | translate}}"
           placeholder="{{'label.selectUser' | translate}}"
           [searchFn]="userSearchFn"
           (close)="blurField()"
           [(ngModel)]="selectedUserId"
>
</ng-select>

import {Component, Input} from '@angular/core';
import {MTabsComponent} from '../../Molecules/m-tabs/m-tabs.component';

@Component({
    selector: 'a-tab',
    templateUrl: './a-tab.component.html',
    styleUrls: ['./a-tab.component.scss']
})
export class ATabComponent {

    @Input()
    public title: string;

    @Input()
    public fragment: string;

    public selected: boolean = false;

    @Input()
    public icon: string;

    constructor(tabs: MTabsComponent) {
        tabs.registerTab(this);
    }
}

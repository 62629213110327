import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Device} from 'lib-devices';

@Component({
    selector: 'l-device-tabs-information',
    templateUrl: './l-device-tabs-information.component.html',
    styleUrls: ['./l-device-tabs-information.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-x'}
})

export class LDeviceTabsInformationComponent {

    @Input()
    public device: Device;

    @Input()
    public users;

    @Input()
    public storedUser;

    @Output()
    renewDevice = new EventEmitter<void>();

    public emitRenewDevice() {
        this.renewDevice.emit();
    }
}

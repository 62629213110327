<o-device-block-os-form class="cell auto"
                        (renewDevice)="emitRenewDevice()"
                        [device]="device"
                        [users]="users"
                        [storedUser]="storedUser"></o-device-block-os-form>

<div class="cell small-2"></div>

<div class="cell shrink">
    <a-space-available-display [spaceTotal]="device?.spaceTotal"
                               [spaceAvailable]="device?.spaceAvailable"></a-space-available-display>

    <spacer [size]="24"></spacer>

    <!--TODO@DEV : plug app and server version info -->
    <a-app-version [appVersion]="device?.appVersion"></a-app-version>
</div>

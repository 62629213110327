<div [hidden]="!device">

    <a-device-preview [device]="device"></a-device-preview>

    <div class="reservationHeader-card" [ngClass]="availabilityCssClass">
        <div [class.ghost]="loadingDevice" class="grid-x grid-padding-y grid-padding-x grid-container align-middle align-justify">
            <div class="cell shrink reservationHeader-name">
                <div class="large-ghost-size">
                    {{device?.model}}

                </div>
            </div>
            <div class="cell shrink">
                <div class="grid-x grid-padding-x">
                    <a-user-display class="cell shrink"
                                    [isGhost]="loadingDevice"
                                    [user]="device?.currentUser"
                                    [availability]="device?.availability"></a-user-display>

                    <a-battery-display class="cell shrink" *ngIf="device?.batteryPercentage"
                                       [batteryLevel]="device?.batteryPercentage"></a-battery-display>
                </div>

                <spacer size="4"></spacer>

                <a-sync-display *ngIf="!loadingDevice" [date]="device?.lastNativeSyncDate"></a-sync-display>
            </div>
        </div>
        <div [class.ghost]="loadingDevice">
            <div class="grid-x align-stretch align-justify">
                <div class="cell auto reservationHeader-availability" [ngClass]="availabilityCssClass">
                    <div class="small-ghost-size">
                        {{'availability.' + device?.availability | translate}}
                    </div>
                </div>
                <div class="cell phone-8">
                    <a-device-name [isGhost]="loadingDevice" [deviceName]="device?.name"
                                   [availability]="device?.availability"></a-device-name>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="grid-container">
    <div class="grid-x align-middle grid-margin-x">
        <!--<a-button-back class="cell shrink" (click)="back()" [isHidden]="currentFolderIsRoot"
                       labelKey="button.back"></a-button-back>-->

        <m-path-display class="cell auto" [path]="currentFolderPath"
                        (changePathEmitter)="updateCurrentFolderPath($event.valueOf())"></m-path-display>
        <a-button-default class="cell shrink" [class.is-hidden]="currentDepth>0" (click)="showNewFolderPopup()"
                          labelKey="button.newFolder"></a-button-default>
    </div>


    <ng-container *ngIf="currentFolderIsRoot && recentFiles.files.length > 0">
        <a-section-title>
            <i class="fas fa-images"></i>
            {{'files.recent' | translate}}
        </a-section-title>
        <div class="grid-x phone-up-1 tablet-portrait-up-2 tablet-landscape-up-3 desktop-up-4 big-desktop-up-5">
            <a-file-display class="cell" *ngFor="let file of recentFiles.files; let fileIndex = index;"
                            [deviceFile]="file"
                            [fileUrl]="getFileUrl(file)"
                            [index]="[-1, fileIndex]"
                            (askIfSureDeleteEmitter)="askIfSureDeleteFile($event.valueOf())"
                            (downloadFileEmitter)="downloadFile($event.valueOf())"
                            (showFilePreviewEmitter)="showFilePreview($event.valueOf())"
                            (reloadFiles)="reloadFiles()"
                            (click)="fileClick(file)">
            </a-file-display>
        </div>
        <spacer [size]="32"></spacer>
    </ng-container>

    <ng-container *ngFor="let fileGroup of fileGroups; let groupIndex = index;">
        <a-section-title>
            <i class="fas fa-folder" *ngIf="fileGroup.key === -1"></i>
            <i class="fas fa-images" *ngIf="fileGroup.key !== -1"></i>
            {{fileGroup.label | translate}}
        </a-section-title>
        <div class="grid-x phone-up-1 tablet-portrait-up-2 tablet-landscape-up-3 desktop-up-4 big-desktop-up-5"
             [ngClass]="{'grid-margin-x grid-margin-y' : fileGroup.key === -1}">
            <a-file-display class="cell" *ngFor="let file of fileGroup.files; let fileIndex = index;"
                            [deviceFile]="file"
                            [fileUrl]="getFileUrl(file)"
                            [index]="[groupIndex, fileIndex]"
                            (askIfSureDeleteEmitter)="askIfSureDeleteFile($event.valueOf())"
                            (downloadFileEmitter)="downloadFile($event.valueOf())"
                            (showFilePreviewEmitter)="showFilePreview($event.valueOf())"
                            (reloadFiles)="reloadFiles()"
                            (click)="fileClick(file)">
            </a-file-display>
        </div>
        <spacer [size]="32"></spacer>
    </ng-container>

    <ng-container *ngIf="fileGroups.length === 0 && currentFolderPath === 'root'">
        <spacer [size]="128"></spacer>
        <div class="grid-x align-center">
            <a-no-folder class="cell shrink"></a-no-folder>
        </div>
    </ng-container>

    <ng-container *ngIf="fileGroups.length === 0 && currentFolderPath !== 'root'">
        <spacer [size]="128"></spacer>
        <div class="grid-x align-center">
            <a-no-files class="cell shrink"></a-no-files>
        </div>
    </ng-container>
</div>

<div class="reveal tiny _overflowAllowed" data-reveal #newFolderPopup>
    <a-section-title [faIconClass]="'fas fa-folder-plus'" [labelKey]="'label.createNewFolder'"></a-section-title>

    <div class="grid-container">
        <input type="text" [(ngModel)]="newFolderName"
               placeholder="{{'label.name' | translate}}">

        <div class="grid-x grid-margin-x align-center">
            <div class="cell shrink">
                <a-button-cancel (click)="cancelFolderCreation()" labelKey="button.cancel"></a-button-cancel>
            </div>
            <div class="cell shrink">
                <a-button-submit (click)="createNewFolder()" [disabled]="!newFolderName"
                                 labelKey="button.create"></a-button-submit>
            </div>
        </div>
    </div>
</div>


<m-file-preview [showPreview]="showingFilePreview"
                [deviceFile]="fileToPreview"
                [fileUrl]="fileToPreview?getFileUrl(fileToPreview):''"
                (closeFilePreviewEmitter)="closeFilePreview()"
                [canGoBack]="previewCanGoBack"
                [canGoForward]="previewCanGoForward"
                (downloadFileEmitter)="downloadFile($event.valueOf())"
                (askIfSureDeleteEmitter)="askIfSureDeleteFile($event.valueOf())"
                (showNextFileEmitter)="changeCurrentPreviewFileByRelativeFileIndex(1)"
                (showPreviousFileEmitter)="changeCurrentPreviewFileByRelativeFileIndex(-1)"
></m-file-preview>
